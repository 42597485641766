import React, { useEffect } from "react";
import { AdminOrderList as AdminOrderListComponent } from "../components/Order";

export function AdminOrderList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AdminOrderListComponent />;
}
