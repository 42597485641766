import React, { useEffect, useRef, useState } from "react";
import { getBasicClient } from "../services";
import {
  ambassadorHero,
  ambassadorSlide1,
  ambassadorSlide2,
  ambassadorSlide3,
} from "../assets";
import { Carousel } from "react-responsive-carousel";

const slides = [
  {
    title: "Join the Furnitrade Ambassador community, make a difference",
    description:
      "Become more than just an everyday student and help us bring sustainable and seamless furniture rentals to your peers and college. Furnitrade Ambassadors celebrate the uniqueness of everyone, earn great commissions, and above all else, make affordable furniture accessible for everyone. Are you ready to make a positive impact in your own way?",
    backgroundColor: "bg-green",
    image: ambassadorSlide1,
  },
  {
    title: "Is becoming a Furnitrade Ambassador for me?",
    description:
      "Furnitrade Ambassadors typically are university students that are highly involved within their respective communities. If you enjoy providing your peers with sustainable solutions, Furnitrade Ambassadors is the place to be.",
    backgroundColor: "bg-blue",
    image: ambassadorSlide2,
  },
  {
    title: "The Furnitrade Ambassadors program in a Nutshell",
    description:
      "Each of our Ambassadors are provided with their own unique referral code that can be shared with their peers. Not only will the code give a 5% discount on all purchases, the ambassador will receive a 10% commission on all orders using their code. With an uptrend of students living off-campus, the earnings potential are unlimited!",
    backgroundColor: "bg-red",
    image: ambassadorSlide3,
  },
];

export function Ambassador() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const ambassadorFormRef = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!name || !email || !description)
      return setError("Please enter mandatory fields!");

    const apiClient = getBasicClient();
    const replacementRequestRes = await apiClient.post(
      "/api/ambassador/request",
      { name, email, description }
    );

    if (replacementRequestRes.status === 200) {
      setName("");
      setEmail("");
      setDescription("");
      setSuccessMessage(replacementRequestRes.data.message);
      setError("");
    } else {
      setError(replacementRequestRes.message);
      setSuccessMessage("");
    }
  };

  const scrollToBottom = () => {
    const y =
      ambassadorFormRef.current.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y,
      behavior: "smooth",
    });
  };

  return (
    <div className="home container fill">
      <div className="bg-white">
        <div
          className="ambassador-hero"
          style={{
            backgroundPosition: "50% 50%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("${ambassadorHero}")`,
            width: "100%",
            height: "540px",
          }}
        >
          <p className="ambassador-hero-white-angle"></p>
        </div>

        <div className="ambassador-join-section">
          <p className="white-angle"></p>
          <div className="join-section-content">
            <h3>Join a community, make a difference</h3>
            <p>
              Furnitrade Ambassadors are on a mission to make moving-in easy for
              all students and frequent movers alike. Join today and help bring
              our mission to life of creating a sustainable and seamless
              furniture rental experience.
            </p>

            <button className="black-bg accent" onClick={scrollToBottom}>
              Become an ambassador
            </button>
          </div>
        </div>

        <Carousel
          showThumbs={false}
          showStatus={false}
          interval={4000}
          autoPlay={true}
          infiniteLoop={true}
          onChange={(index) => setCurrentSlide(index)}
        >
          {slides.map((slide, index) => (
            <aside
              key={index}
              className={`app-cta overflow-bg ${slides[currentSlide].backgroundColor}`}
            >
              <div className="text">
                <div className="center">
                  <h1>{slide.title}</h1>
                  <p>{slide.description}</p>
                </div>
              </div>
              <div className="image" style={{ backgroundImage: "none" }}>
                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      backgroundPosition: "50% 50%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url("${slide.image}")`,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            </aside>
          ))}
        </Carousel>
      </div>

      <div ref={ambassadorFormRef} className="view-login">
        <div className="left">
          <h3>Become an Ambassador</h3>
          <p className="margin-top-30">
            After reviewing and approving your application, our team will email
            you a unique ambassador code that can be used for a 5% discount on
            all purchases.
          </p>
          <form method="post" className="login" onSubmit={handleSubmit}>
            <label className="fixed" htmlFor="name">
              Name
            </label>
            <input
              className="input box"
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label className="fixed" htmlFor="email">
              Email
            </label>
            <input
              className="input box"
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            />

            <label className="fixed" htmlFor="description">
              Description
            </label>
            <textarea
              style={{ width: "100%" }}
              className="input box"
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div className="margin-top-30">
              <div className=" error">
                <p>{error}</p>
              </div>
              <div className=" success">
                <p>{successMessage}</p>
              </div>
            </div>

            <input
              className="button black-bg white"
              id="login_submit"
              name="login_submit"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
