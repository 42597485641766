export const LOGIN_SAGA = "LOGIN_SAGA";
export const SIGNUP_SAGA = "SIGNUP_SAGA";
export const LOGOUT_SAGA = "LOGOUT_SAGA";
export const VALIDATE_USER_SAGA = "VALIDATE_USER_SAGA";
export const START_LOADING_SAGA = "START_LOADING_SAGA";
export const STOP_LOADING_SAGA = "STOP_LOADING_SAGA";
export const ADD_TOAST_SAGA = "ADD_TOAST_SAGA";
export const REMOVE_TOAST_SAGA = "REMOVE_TOAST_SAGA";

// student packages
export const LOAD_INITIALS_SP_SAGA = "LOAD_INITIALS_SP_SAGA";
export const LOAD_PRODUCTS_FOR_PACKAGE_SAGA = "LOAD_PRODUCTS_FOR_PACKAGE_SAGA";
export const LOAD_ADDITIONAL_PRODUCTS_FOR_PACKAGE_SAGA =
  "LOAD_ADDITIONAL_PRODUCTS_FOR_PACKAGE_SAGA";

//cart
export const UPDATE_CART_SAGA = "UPDATE_CART_SAGA";
export const ADD_ITEM_TO_CART_SAGA = "ADD_ITEM_TO_CART_SAGA";
export const REMOVE_ITEM_FROM_CART_SAGA = "REMOVE_ITEM_FROM_CART_SAGA";
export const INCREMENT_QTY_CART_SAGA = "INCREMENT_QTY_CART_SAGA";
export const DECREMENT_QTY_CART_SAGA = "DECREMENT_QTY_CART_SAGA";
export const CLEAR_CART_SAGA = "CLEAR_CART_SAGA";

export const LOAD_TODO_SAGA = "LOAD_TODOS_SAGA";
export const ADD_TODO_SAGA = "ADD_TODOS_SAGA";
