import { getAPIClient, getBasicClient } from "../../services";

export const UserApis = {
  login: function (email, password, type) {
    const apiClient = getAPIClient();
    const payload = {
      email,
      password,
    };
    if (type) {
      payload.type = type;
    }
    return apiClient.post("/auth/login", payload);
  },
  signup: function (data) {
    const apiClient = getAPIClient();
    return apiClient.post("/auth/signup", data);
  },
  fetchAuthData: function (id) {
    const apiClient = getAPIClient();
    return apiClient.get("/auth/me");
  },
  getAuthStatusWithBasicClient: function (id) {
    const apiClient = getBasicClient();
    return apiClient.get("/auth/me");
  },
};
