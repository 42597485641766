import { all } from "redux-saga/effects";

import { todoSagas } from "./todo";
import { userSagas } from "./user";
import { loaderSagas } from "./loader";
import { toastsSagas } from "./toasts";
import { studentPackaesSagas } from "./studentpackages";
import { cartSagas } from "./cart";

export default function* rootSaga() {
  yield all([
    ...todoSagas,
    ...userSagas,
    ...loaderSagas,
    ...toastsSagas,
    ...studentPackaesSagas,
    ...cartSagas,
  ]);
}
