import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="view-how container fill">
        <div className="subnav h5">
          <Link to={"/furniturepackages"}>Furniture Packages</Link>
          <Link to={"/coming-soon"}>Marketplace</Link>
          <Link to={"/?section=faq"}>FAQ</Link>
          <Link to={"/"}>Contact Us</Link>
        </div>

        <div className="bg-green">
          <aside className="app-list maxwidth">
            <div className="title">
              <h3>Furniture Packages</h3>
              <Link to={"/furniturepackages"}>
                <button>Furniture Packages</button>
              </Link>
            </div>
            <div className="list">
              <ol>
                <li>
                  <h5>Choose Lease Term & Package</h5>
                  <p>
                    Furnitrade offers furniture packages suitable for groups as
                    small as one person and up to 5 total roommates in 3 - 12
                    month leases.
                  </p>
                </li>
                <li>
                  <h5>Pick Primary items</h5>
                  <p>
                    All packages include the basic living necessities for each
                    roommate.
                  </p>
                </li>
                <li>
                  <h5>Pick Secondary Items</h5>
                  <p>
                    All packages include store credits that can be used to lease
                    any additional item. Freely decorate your living space
                    without any long-term commitments.
                  </p>
                </li>
                <li>
                  <h5>Schedule delivery & pickup</h5>
                  <p>
                    Once you schedule your delivery and pickup, our team will
                    come and set up your furniture at no additional cost.
                  </p>
                </li>
              </ol>
            </div>
          </aside>
        </div>

        <div className="bg-blue">
          <aside className="app-list maxwidth">
            <div className="title">
              <h3>Why?</h3>
            </div>
            <div className="list">
              <ol>
                <li>
                  <h5>Convenience</h5>
                  <p>
                    Moving is hard enough, and furnishing your place shouldn't
                    add to that stress. We make the process of furnishing your
                    living space as easy as possible by offering free delivery,
                    installation, & pickup, so you don't need to worry about the
                    hassles of moving furniture or storing it over the summer.
                  </p>
                </li>
                <li>
                  <h5>Affordability</h5>
                  <p>
                    Fully furnish your living space on a college budget. Rent
                    our move-in ready student packages or pre-owned furniture
                    from our marketplace.
                  </p>
                </li>
                <li>
                  <h5>Fully Customizable</h5>
                  <p>
                    Our student packages are fully customizable with furniture
                    choices curated to the needs of a college student. If those
                    pieces don't suit your taste, check out our marketplace that
                    is home to a wide-selection of pre-owned furniture ready to
                    be rented.
                  </p>
                </li>
                <li>
                  <h5>Sustainable</h5>
                  <p>
                    Furnitrade's circular business model of rental furniture was
                    created with the goal in mind to reduce the 9.8 million tons
                    of furniture waste one couch at a time.
                  </p>
                </li>
              </ol>
            </div>
          </aside>
        </div>

        <div className="bg-red">
          <aside className="app-list maxwidth">
            <div className="title">
              <h3>Marketplace</h3>
              <Link to={"/coming-soon"}>
                <button>Marketplace</button>
              </Link>
            </div>
            <div className="list">
              <ol>
                <li>
                  <h5>Choose Lease Term</h5>
                  <p>
                    Start by selecting a lease term, available in 3 - 12 month
                    options
                  </p>
                </li>
                <li>
                  <h5>Browse</h5>
                  <p>
                    Explore our wide-selection of pre-owned furniture that is
                    carefully picked by our team. Each piece is refurbished to
                    like-new quality and ready to be rented.
                  </p>
                </li>
                <li>
                  <h5>Schedule Delivery & Pickup</h5>
                  <p>
                    Our team has you covered with free delivery, installation, &
                    pickup, so sit back, relax, and watch as your living space
                    comes to life.
                  </p>
                </li>
              </ol>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}
