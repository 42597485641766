import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ACTIONS } from "../redux";

export function AdminMainLayout() {
  const { isLoggedIn, data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch({ type: ACTIONS.LOGOUT_SAGA });
  };
  if (!(isLoggedIn && userData.type === "ADMIN")) {
    return <Navigate to="/admin" />;
  }
  const pathname = location.pathname;
  return (
    <div className="admin-outer">
      <div className="admin-left">
        <h5>Welcome Admin!</h5>

        <div>
          <ul className="admin-side-menu">
            <li
              className={`${pathname === "/admin/orders" && "active"}`}
              onClick={() => navigate("/admin/orders")}
            >
              Orders
            </li>
            <li
              className={`${pathname === "/admin/categories" && "active"}`}
              onClick={() => navigate("/admin/categories")}
            >
              Categories
            </li>
            <li
              className={`${
                pathname === "/admin/student-packages" && "active"
              }`}
              onClick={() => navigate("/admin/student-packages")}
            >
              Student Packages
            </li>
            <li
              className={`${pathname === "/admin/products" && "active"}`}
              onClick={() => navigate("/admin/products")}
            >
              Products
            </li>
            <li
              className={`${pathname === "/admin/discount-codes" && "active"}`}
              onClick={() => navigate("/admin/discount-codes")}
            >
              Discount Codes
            </li>
            <li onClick={handleLogout}>Logout</li>
          </ul>
        </div>
      </div>
      <div className="admin-right">
        <Outlet />
      </div>
    </div>
  );
}
