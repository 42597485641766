import React from "react";
import { useSelector } from "react-redux";

export function FullScreenLoader() {
  const isLoading = useSelector((state) => state.loader).isLoading;

  if (!isLoading) {
    return <></>;
  }

  return <div>Full Screen Loader...</div>;
}
