import { getAPIClient } from "../../services";

export const StudentPackagesApis = {
  fetchZipcodes: function () {
    const apiClient = getAPIClient();
    return apiClient.get("/api/zipcode");
  },
  fetchStudentPackages: function () {
    const apiClient = getAPIClient();
    return apiClient.get("/api/studentpackage");
  },
  fetchProducts: function (packageId) {
    const apiClient = getAPIClient();
    return apiClient.get("/api/product", {
      params: { packageId },
    });
  },
  fetchAdditionalProducts: function (packageId) {
    const apiClient = getAPIClient();
    return apiClient.get("/api/product", {
      params: { packageId },
    });
  },
};
