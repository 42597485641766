import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
} from "redux-saga/effects";
import { startLoader, stopLoader } from "./loader.slice";
import { START_LOADING_SAGA, STOP_LOADING_SAGA } from "../sagaActions";

function* startLoadingAsync() {
  yield put(startLoader());
}

function* stopLoadingAsync() {
  yield put(stopLoader());
}

function* startLoading() {
  yield takeLatest(START_LOADING_SAGA, startLoadingAsync);
}

function* stopLoading() {
  yield takeLatest(STOP_LOADING_SAGA, stopLoadingAsync);
}

export const loaderSagas = [fork(startLoading), fork(stopLoading)];
