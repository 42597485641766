import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  collectionImage,
  furnitureImage,
  moveFurniture,
  fiveRoommate,
  twoRoommate,
  singleRoommate,
  threeRoommate,
  fourRoomate,
  packageImage,
  bedImage,
  deskImage,
  calendarImage,
  heroLogoImage,
  premiumPackageOneImage,
  premiumPackageTwoImage,
  premiumPackageFiveImage,
  premiumPackageThreeImage,
  premiumPackageFourImage,
} from "../../assets";
import { FAQ } from "../Faq/Faq";
import { Review } from "../Review";

export function HomePage() {
  const faqRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const section = new URLSearchParams(location.search).get("section");
      if (section === "faq") {
        window.scrollTo({ behavior: "smooth", top: faqRef.current.offsetTop });
      }
    }, 2);
  }, [location.search]);

  return (
    <>
      <div className="home container fill">
        <div className="hero-bg">
          <section className="intro container pad maxwidth">
            <div className="row">
              <img src={heroLogoImage} alt={"hero-logo"} />
            </div>
            <div className="row buttons-row">
              <div className="cta">
                <div className="buttons" style={{ display: "unset" }}>
                  <Link
                    to={"/furniturepackages"}
                    className="button outline black"
                    style={{ margin: "0 auto" }}
                  >
                    <b>Furniture Rentals</b>
                  </Link>
                  {/* <Link to={"/coming-soon"} className="button outline black">
                  Marketplace
                </Link> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <aside className="app-cta overflow-bg">
          <div className="text">
            <div className="center">
              <h1>Circular Business Model</h1>
              <p>
                Furnitrade is an online furniture rental platform dedicated to
                reducing <b>the 9.8 million tons of furniture waste</b> produced
                annually through our circular business model. Each piece of
                furniture that is rented will be new or in like-new quality.
                Before any furniture is rented, it is first cleaned &
                refurbished by in-house experts to maintain a consistent quality
                on all items ensuring every customer enjoys the same great
                experience.
              </p>
              <Link to={"/howitworks"}>
                <button className="black-bg accent">How it Works</button>
              </Link>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${collectionImage}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
        <section className="shop-listing maxwidth">
          <h4>Standard Packages</h4>
          <div className="package-grid grid">
            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={singleRoommate} alt={"single-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>Single Person</b>
                  </div>
                  <div>
                    Primary items: 1 bedframe, 1 desk, 1 desk chair, 1 dining
                    table, 2 dining chairs
                  </div>
                  <div>Secondary items: 400 credits</div>

                  <div className="rent-div">
                    <strong>$500 - $800</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package large">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={fiveRoommate} alt={"five-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>5 Roommates</b>
                  </div>
                  <div>
                    Primary items: 5 bedframes, 1 dining table, 5 dining chairs,
                    sofa
                  </div>
                  <div>Secondary items: 550 credits</div>

                  <div className="rent-div">
                    <strong>$1200 - $1750</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={threeRoommate} alt={"three-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>3 Roommates</b>
                  </div>
                  <div>
                    Primary items: 3 bedframes, 1 dining table, 3 dining chairs,
                    sofa, 3 desks, 3 desk chairs
                  </div>
                  <div>Secondary items: 450 credits</div>

                  <div className="rent-div">
                    <strong>$900 - $1450</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={twoRoommate} alt={"two-roommate"} />
                </div>
                <div className="details">
                  <div className="name">2 Roommates</div>
                  <div>
                    Primary items: 2 bedframes, 1 dining table, 2 dining chairs,
                    2 desks, 2 desk chairs
                  </div>
                  <div>Secondary items: 400 credits</div>

                  <div className="rent-div">
                    <strong>$650 - $1050</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={fourRoomate} alt={"four-roommate"} />
                </div>
                <div className="details">
                  <div className="name">4 Roommates</div>
                  <div>
                    Primary items: 4 bedframes, 1 dining table, 4 dining chairs,
                    sofa
                  </div>
                  <div>Secondary items: 500 credits</div>

                  <div className="rent-div">
                    <strong>$1000 - $1600</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="package-grid linear">
            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={singleRoommate} alt={"single-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>Single Person</b>
                  </div>
                  <div>
                    Primary items: 1 bedframe, 1 desk, 1 desk chair, 1 dining
                    table, 2 dining chairs
                  </div>
                  <div>Secondary items: 400 credits</div>

                  <div className="rent-div">
                    <strong>$500 - $800</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={twoRoommate} alt={"two-roommate"} />
                </div>
                <div className="details">
                  <div className="name">2 Roommates</div>
                  <div>
                    Primary items: 2 bedframes, 1 dining table, 2 dining chairs,
                    2 desks, 2 desk chairs
                  </div>
                  <div>Secondary items: 400 credits</div>

                  <div className="rent-div">
                    <strong>$650 - $1050</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={threeRoommate} alt={"three-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>3 Roommates</b>
                  </div>
                  <div>
                    Primary items: 3 bedframes, 1 dining table, 3 dining chairs,
                    sofa, 3 desks, 3 desk chairs
                  </div>
                  <div>Secondary items: 450 credits</div>

                  <div className="rent-div">
                    <strong>$900 - $1450</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={fourRoomate} alt={"four-roommate"} />
                </div>
                <div className="details">
                  <div className="name">4 Roommates</div>
                  <div>
                    Primary items: 4 bedframes, 1 dining table, 4 dining chairs,
                    sofa
                  </div>
                  <div>Secondary items: 500 credits</div>

                  <div className="rent-div">
                    <strong>$1000 - $1600</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package large">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img src={fiveRoommate} alt={"five-roommate"} />
                </div>
                <div className="details">
                  <div className="name">
                    <b>5 Roommates</b>
                  </div>
                  <div>
                    Primary items: 5 bedframes, 1 dining table, 5 dining chairs,
                    sofa
                  </div>
                  <div>Secondary items: 550 credits</div>

                  <div className="rent-div">
                    <strong>$1200 - $1750</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <div className="how-it-works">
          <section className="padding-top-50 padding-bottom-50 maxwidth">
            <h3>How it works</h3>
            <div className="row-grid">
              <div>
                <img src={packageImage} alt={"choose-lease-term-and-package"} />
                <br />
                <p className="title">
                  <b>Choose Lease Term & Package</b>
                </p>
                <p className="description">
                  Furnitrade offers furniture packages suitable for groups as
                  small as one person and up to 5 total roommates in 3 - 12
                  month leases.
                </p>
              </div>
              <div>
                <img src={bedImage} alt={"pick-included-items"} />
                <br />
                <p className="title">
                  <b>Pick Primary items</b>
                </p>
                <p className="description">
                  All packages include an arrangement of basic furniture items
                  needed for each roommate. These are items such as bedframes,
                  desks, dining tables, and sofas.
                </p>
              </div>
              <div>
                <img src={deskImage} alt={"pick-additional-items"} />
                <br />
                <p className="title">
                  <b>Pick Secondary Items</b>
                </p>
                <p className="description">
                  All packages include store credits that can be used to lease
                  any additional item. Freely decorate your living space without
                  any long-term commitments.{" "}
                </p>
              </div>
              <div>
                <img src={calendarImage} alt={"delivery-and-pickup"} />
                <br />
                <p className="title">
                  <b>Schedule delivery & pickup</b>
                </p>
                <p className="description">
                  Once you schedule your delivery and pickup, our team will come
                  and set up your furniture at no additional cost.
                </p>
              </div>
            </div>
            <Link to={"/howitworks"}>
              <button className="learn-more">Learn more</button>
            </Link>
          </section>
        </div>
        <Review />
        <aside className="app-cta overflow-bg reverse bg-red">
          <div className="text">
            <div className="center">
              <h1>Free Delivery, Installation, & Pickup</h1>
              <p>
                Delivery, installation, & pickup are included with every package
                purchase. We'll do all the heavy lifting and hard work, so kick
                back, relax, and watch as your living space comes to life.
              </p>
              <Link to={"/furniturepackages"}>
                <button className="black-bg accent">Furniture Packages</button>
              </Link>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${moveFurniture}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
        <section className="shop-listing maxwidth">
          <h4>Premium Packages</h4>
          <div className="package-grid">
            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img
                    src={premiumPackageOneImage}
                    alt={"premium-package-one"}
                  />
                </div>
                <div className="details">
                  <div className="name">
                    <b>Single Person</b>
                  </div>
                  <div>
                    Primary items: 1 bedframe, 1 dining table, 2 dining chairs,
                    1 sofa
                  </div>
                  <div>Secondary items: 650 credits</div>

                  <div className="rent-div">
                    <strong>$1250 - $1850</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package large">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img
                    src={premiumPackageFiveImage}
                    alt={"premium-package-five"}
                  />
                </div>
                <div className="details">
                  <div className="name">
                    <b>5 Roommates</b>
                  </div>
                  <div>
                    Primary items: 5 bedframes, 1 dining table, 5 dining chairs,
                    1 sofa
                  </div>
                  <div>Secondary items: 900 credits</div>

                  <div className="rent-div">
                    <strong>$2400 - $2900</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img
                    src={premiumPackageThreeImage}
                    alt={"premium-package-three"}
                  />
                </div>
                <div className="details">
                  <div className="name">
                    <b>3 Roommates</b>
                  </div>
                  <div>
                    Primary items: 3 bedframes, 1 dining table, 3 dining chairs,
                    1 sofa
                  </div>
                  <div>Secondary items: 740 credits</div>

                  <div className="rent-div">
                    <strong>$1800 - $2300</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img
                    src={premiumPackageTwoImage}
                    alt={"premium-package-two"}
                  />
                </div>
                <div className="details">
                  <div className="name">2 Roommates</div>
                  <div>
                    Primary items: 2 bedframes, 1 dining table, 2 dining chairs,
                    1 sofa
                  </div>
                  <div>Secondary items: 650 credits</div>

                  <div className="rent-div">
                    <strong>$1450 - $1950</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>

            <div className="package">
              <Link to={"/furniturepackages"}>
                <div className="image">
                  <img
                    src={premiumPackageFourImage}
                    alt={"premium-package-four"}
                  />
                </div>
                <div className="details">
                  <div className="name">4 Roommates</div>
                  <div>
                    Primary items: 4 bedframes, 1 dining table, 4 dining chairs,
                    1 sofa
                  </div>
                  <div>Secondary items: 840 credits</div>

                  <div className="rent-div">
                    <strong>$2100 - $2600</strong>
                    <button>Rent</button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <aside className="app-cta overflow-bg reverse">
          <div className="text">
            <div className="center">
              <h1>Furnish sustainably and reduce waste</h1>
              <p>
                Through Furnitrade, we can keep furniture out of landfills and
                reduce waste one apartment at a time. All furniture items that
                come back to us are thoroughly cleaned and repaired to a
                like-new quality, and if it doesn’t meet our standards, it is
                donated to local homeless shelters in Los Angeles.
              </p>
              <Link to={"/social"}>
                <button className="black-bg accent">
                  Social Responsibility
                </button>
              </Link>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${furnitureImage}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
        <FAQ innerRef={faqRef} />
      </div>
    </>
  );
}
