import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ACTIONS } from "../../redux";
import { getAPIClient, getBasicClient } from "../../services";
import { HOMEPAGE_REDIRECT_TO_URL, LeaseTerms } from "../../constants";
import { UserApis } from "../../redux/user/user.api";
import {
  getNearestPastWeekendDay,
  getNextWeekendDay,
  setCookie,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { Modal } from "../Modal/Modal";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TIME_SLOTS = [
  "08:00 - 10:00",
  "10:00 - 12:00",
  "12:00 - 14:00",
  "14:00 - 16:00",
  "16:00 - 18:00",
  "18:00 - 20:00",
  "20:00 - 22:00",
];

function CartView(props) {
  const { cartProducts, totalPrice } = props;
  return (
    <>
      <h4>Selected Products</h4>
      <h5>Primary</h5>
      <div className="product-grid">
        {cartProducts
          .filter((product) => !product.additional)
          .map(({ img, name, _id, additional, price, quantity }) => (
            <div key={_id} className="product">
              <div className="image">
                <img src={img} alt={name} />
              </div>
              <div className="details">
                <div className="name">{name}</div>
                <p>Quantity: {quantity}</p>
              </div>
            </div>
          ))}
      </div>

      <h5>Secondary</h5>
      <div className="product-grid">
        {cartProducts
          .filter((product) => product.additional)
          .map(({ img, name, _id, additional, price, quantity }) => (
            <div key={_id} className="product">
              <div className="image">
                <img src={img} alt={name} />
              </div>
              <div className="details">
                <div className="name">{name}</div>
                <p>Quantity: {quantity}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export function StudentPackageStepFour(props) {
  const client = getAPIClient();
  const { goToPreviousPage } = props;
  const [showModal, setShowModal] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState(getNextWeekendDay());
  const [deliveryDateError, setDeliveryDateError] = useState(undefined);

  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState("");
  const [deliveryTimeSlotError, setDeliveryTimeSlotError] = useState(true);

  const [pickupDate, setPickupDate] = useState(moment().add(11, "days"));
  const [pickupDateError, setPickupDateError] = useState(undefined);
  const [maxPickupDate, setMaxPickupDate] = useState(moment());

  const [pickupTimeSlot, setPickupTimeSlot] = useState("");
  const [pickupTimeSlotError, setPickupTimeSlotError] = useState(true);

  const cart = useSelector((state) => state.cart);
  const { errorMessage, items, leaseTerm, packageId, zipCode } = cart;
  const { products, additionalProducts, packages } = useSelector(
    (state) => state.studentPackages
  );
  const { isLoggedIn, isChecking } = useSelector((state) => state.user);

  const studentPackage = useMemo(
    () => packages.find((spackage) => spackage._id === packageId),
    [packages, packageId]
  );
  const [checkoutPrice, setCheckoutPrice] = useState(
    studentPackage.variants[leaseTerm]
  );
  const [discount, setDiscount] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeStatus, setDiscountCodeStatus] = useState("");

  const productIds = useMemo(
    () => products.map((product) => product._id),
    [products]
  );
  const packageProductDetails = useMemo(
    () =>
      items
        .filter(({ id }) => productIds.includes(id))
        .map(({ id, quantity }) => ({
          ...products.find((product) => product._id === id),
          additional: false,
          quantity,
        })),
    [items, productIds, products]
  );

  const additionalProductIds = useMemo(
    () => additionalProducts.map((product) => product._id),
    [additionalProducts]
  );

  const additionalProductDetails = useMemo(
    () =>
      items
        .filter(({ id }) => additionalProductIds.includes(id))
        .map(({ id, quantity }) => ({
          ...additionalProducts.find((product) => product._id === id),
          additional: true,
          quantity,
        })),
    [items, additionalProductIds, additionalProducts]
  );

  const cartProducts = [...packageProductDetails, ...additionalProductDetails];

  const checkoutHandler = async () => {
    if (
      deliveryDateError ||
      pickupDateError ||
      deliveryTimeSlotError ||
      pickupTimeSlotError
    ) {
      return;
    }

    const authRes = await UserApis.getAuthStatusWithBasicClient();

    if (authRes.status === 200) {
      const res = await client.post("/api/payment", {
        cart,
        deliveryDate: deliveryDate.toISOString(),
        deliveryTimeSlot,
        pickupDate: pickupDate.toISOString(),
        pickupTimeSlot,
        discountCode,
      });
      if (res.status === 200) {
        window.location.href = res.data.url;
      }
    } else {
      setCookie(HOMEPAGE_REDIRECT_TO_URL, "/furniturepackages?step=4");
      window.location.href = "./login";
    }
  };

  const cartProps = {
    cartProducts,
    totalPrice: studentPackage?.variants?.[leaseTerm],
  };

  const leaseTermnInMonths = LeaseTerms.find(
    (term) => term.value === cart.leaseTerm
  ).months;

  useEffect(() => {
    const _maxPickupDate = moment(deliveryDate).add(
      leaseTermnInMonths,
      "months"
    );
    setPickupDate(getNearestPastWeekendDay(_maxPickupDate));
    setMaxPickupDate(_maxPickupDate);
  }, [deliveryDate, leaseTermnInMonths]);

  const handleDiscountCodeApply = async () => {
    const response = await client.get("/api/discountcode/check", {
      params: { code: discountCode },
    });

    if (response?.data) {
      const discount = Math.floor(
        studentPackage.variants[leaseTerm] * (response.data.discount / 100)
      );
      setDiscount(discount);
      setDiscountCodeStatus("Coupon code applied successfully!");
      setCheckoutPrice(studentPackage.variants[leaseTerm] - discount);
    } else {
      setDiscount(0);
      setDiscountCode("");
      setDiscountCodeStatus("");
      setCheckoutPrice(studentPackage.variants[leaseTerm]);
    }
  };

  const disableWeekdays = (date) => {
    return date.day() !== 0 && date.day() !== 5 && date.day() !== 6;
  };

  return (
    <>
      <div className="page-head">
        <section className="maxwidth">
          <h4 className="title">Cart</h4>

          <div className="buttons">
            <button
              className="button black-bg white"
              onClick={goToPreviousPage}
            >
              {"<"}&nbsp; Previous
            </button>
            <button
              className="button black-bg white"
              onClick={() => setShowModal(true)}
            >
              Next &nbsp;{">"}
            </button>
          </div>
        </section>
      </div>

      <div className="view-shop app-static-shop container fill maxwidth">
        <section className="shop-listing maxwidth">
          <div className="checkout">
            <div className="left">
              <CartView {...cartProps} />
            </div>
          </div>
        </section>
      </div>

      <Modal modalVisible={showModal} setModalVisible={setShowModal}>
        <div className="checkout-modal">
          <div className="checkout-details">
            <div className="left">
              <table>
                <tr>
                  <td>Lease Term</td>
                  <td>
                    {leaseTerm[0].toUpperCase() + leaseTerm.slice(1)} Months
                  </td>
                </tr>
                <tr>
                  <td>Package</td>
                  <td>{studentPackage.name}</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>${studentPackage.variants[leaseTerm]}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>${discount}</td>
                </tr>
                <tr>
                  <td>Service charge</td>
                  <td>${Math.floor(checkoutPrice * 0.015)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <b>${checkoutPrice + Math.floor(checkoutPrice * 0.015)}</b>
                  </td>
                </tr>
              </table>
            </div>

            <div className="right">
              <div className="checkout-date">
                <label>Delivery Date</label>
                <DatePicker
                  className="input box date-picker"
                  hintText="Delivery Date"
                  value={deliveryDate}
                  shouldDisableDate={disableWeekdays}
                  onChange={(e) => {
                    setDeliveryDate(e);
                  }}
                  onError={(err) => setDeliveryDateError(err)}
                />

                <label>Delivery Time Slot</label>
                <select
                  className={`select ${
                    deliveryTimeSlotError ? "border-red" : ""
                  }`}
                  value={deliveryTimeSlot}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setDeliveryTimeSlotError(true);
                    } else {
                      setDeliveryTimeSlotError(false);
                    }

                    setDeliveryTimeSlot(e.target.value);
                  }}
                >
                  <option value={""}>Select Delivery Time Slot</option>
                  {TIME_SLOTS.map((slot, index) => (
                    <option key={index} value={slot}>
                      {slot}
                    </option>
                  ))}
                </select>
              </div>

              <div className="checkout-date">
                <label>Pickup Date</label>
                <DatePicker
                  className="input box date-picker"
                  hintText="Pickup Date"
                  value={pickupDate}
                  shouldDisableDate={disableWeekdays}
                  maxDate={maxPickupDate}
                  onChange={(e) => {
                    setPickupDate(e);
                  }}
                  onError={(err) => setPickupDateError(err)}
                />

                <label>Pickup Time Slot</label>
                <select
                  className={`select ${
                    pickupTimeSlotError ? "border-red" : ""
                  }`}
                  value={pickupTimeSlot}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPickupTimeSlotError(true);
                    } else {
                      setPickupTimeSlotError(false);
                    }

                    setPickupTimeSlot(e.target.value);
                  }}
                >
                  <option value={""}>Select Pickup Time Slot</option>
                  {TIME_SLOTS.map((slot, index) => (
                    <option key={index} value={slot}>
                      {slot}
                    </option>
                  ))}
                </select>
              </div>

              <div className="checkout-date">
                <label>Discount code</label>
                <input
                  type="text"
                  className="input box"
                  value={discountCode}
                  onChange={(e) =>
                    setDiscountCode(e.target.value.toLowerCase())
                  }
                />

                <button
                  onClick={handleDiscountCodeApply}
                  className="margin-top-10"
                >
                  Apply
                </button>
                <p className="margin-top-10">{discountCodeStatus}</p>
              </div>
            </div>
          </div>

          <button className="button margin-top-30" onClick={goToPreviousPage}>
            {"<"}&nbsp; Previous
          </button>
          <button
            className="button margin-top-30 margin-left-30"
            onClick={checkoutHandler}
          >
            Checkout &nbsp;{">"}
          </button>
        </div>
      </Modal>
    </>
  );
}
