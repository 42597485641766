import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
} from "redux-saga/effects";
import { addToast, removeToast } from "./toasts.slice";
import { ADD_TOAST_SAGA, REMOVE_TOAST_SAGA } from "../sagaActions";

function* addToastAsync({ payload }) {
  yield put(addToast(payload));
}

function* removeToastAsync({ payload }) {
  yield put(removeToast(payload));
}

function* addToastMessage() {
  yield takeLatest(ADD_TOAST_SAGA, addToastAsync);
}

function* removeToastMessage() {
  yield takeLatest(REMOVE_TOAST_SAGA, removeToastAsync);
}

export const toastsSagas = [fork(addToastMessage), fork(removeToastMessage)];
