import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteImage } from "../../assets";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { ACTIONS } from "../../redux";

export function ProductCard(props) {
  const previewRef = useRef(null);
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);

  const {
    product,
    categoryId,
    cartProduct,
    withCredit = false,
    minHeight = "44px",
  } = props;
  const {
    img,
    name,
    _id: productId,
    price,
    storeCredit,
    imgs,
    dimensions,
    unavailable,
    limitedAvailability,
  } = product;
  const { exists, quantity } = cartProduct;

  const [previewImg, setPreviewImg] = useState(img);

  const openPreview = () => setShowPreview(true);
  const closePreview = () => setShowPreview(false);

  useOnClickOutside(previewRef, closePreview);

  const dispatchCartAction = (type, id) => () => {
    dispatch({
      type,
      payload: {
        id,
      },
    });
  };

  return (
    <div className={`product`}>
      <div className="image" onClick={openPreview}>
        <img src={img} alt={name} />
      </div>
      <div className="details">
        <div>
          <div className="name">{name}</div>
          <div className="credit-details margin-top-10" style={{ minHeight }}>
            {withCredit && <p>Credit: {storeCredit}</p>}
            {unavailable === "yes" && (
              <p className="status text-red">Product is unavailable</p>
            )}
            {limitedAvailability === "yes" && (
              <p className="status text-green">Limited availability</p>
            )}
          </div>
        </div>
        {!exists && unavailable === "no" && (
          <div className="product-counter">
            <button
              className="btn-add-cart"
              onClick={dispatchCartAction(
                ACTIONS.ADD_ITEM_TO_CART_SAGA,
                productId
              )}
            >
              Add To Cart
            </button>
          </div>
        )}
        {exists && unavailable === "no" && (
          <>
            <div className="product-counter">
              <div className="product-counter-left">
                <button
                  onClick={dispatchCartAction(
                    ACTIONS.DECREMENT_QTY_CART_SAGA,
                    productId
                  )}
                >
                  -
                </button>
                <p>{quantity}</p>
                <button
                  onClick={dispatchCartAction(
                    ACTIONS.INCREMENT_QTY_CART_SAGA,
                    productId
                  )}
                >
                  +
                </button>
              </div>
              <button
                className="delete-button"
                onClick={dispatchCartAction(
                  ACTIONS.REMOVE_ITEM_FROM_CART_SAGA,
                  productId
                )}
              >
                <img src={deleteImage} alt="delete-icon" />
              </button>
            </div>
          </>
        )}
      </div>
      <div
        id="myModal"
        className={`custom-modal ${showPreview ? "block" : ""}`}
      >
        <div className="modal-content" ref={previewRef}>
          <span className="close" onClick={closePreview}>
            X
          </span>
          <img className="main-img" src={previewImg} alt={name} />
          <div className="imgs-wrapper">
            {imgs?.length && (
              <>
                {imgs.map((img) => (
                  <img
                    className={`${previewImg === img ? "selected" : ""}`}
                    src={img}
                    alt="img"
                    key={img}
                    onClick={() => {
                      setPreviewImg(img);
                    }}
                  />
                ))}
              </>
            )}
          </div>
          {dimensions && (
            <div className="details">Dimensions: {dimensions}</div>
          )}
        </div>
      </div>
    </div>
  );
}
