import React, { useEffect } from "react";
import { useState } from "react";
import { getBasicClient } from "../../services";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

export function AdminDiscountCodeList() {
  const [discountCodes, setDiscountCodes] = useState([]);
  const navigate = useNavigate();

  const client = getBasicClient();

  const fetchData = async () => {
    const data = await client.get("/api/discountcode");
    setDiscountCodes(data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="button black-bg white"
          onClick={() => {
            navigate("/admin/create-discount-code", {
              state: { edit: true, data: row },
            });
          }}
        >
          Update
        </button>
      ),
    },
  ];

  return (
    <div className="admin-right">
      <h4>Discount Codes</h4>

      <Link
        to={"/admin/create-discount-code"}
        className="button outline black create-button"
      >
        Create Discount Code
      </Link>

      <DataTable columns={columns} data={discountCodes} pagination />
    </div>
  );
}
