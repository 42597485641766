import { combineReducers } from "@reduxjs/toolkit";
import todoReducer from "./todo/todo.slice";
import userReducer from "./user/user.slice";
import loaderReducer from "./loader/loader.slice";
import toastsReducer from "./toasts/toasts.slice";
import studentPackagesReducer from "./studentpackages/studentpackages.slice";
import cartReducer from "./cart/cart.slice";

const reducers = combineReducers({
  todos: todoReducer,
  user: userReducer,
  loader: loaderReducer,
  toasts: toastsReducer,
  studentPackages: studentPackagesReducer,
  cart: cartReducer,
});

export default reducers;
