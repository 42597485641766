import React, { useEffect } from "react";
import { StudentPackageForm as StudentPackageFormComponent } from "../components/StudentPackage/StudentPackageForm";

export function StudentPackageForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <StudentPackageFormComponent />;
}
