import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { plusImage } from "../../assets";

export function FAQ({ innerRef }) {
  const [faqs, setFaqs] = useState([
    {
      question: "Can I change my delivery date?",
      answer:
        "You sure can! Your delivery date can be rescheduled by emailing order@furnitrade.biz with your order information and updated delivery date. Just make sure to do this at least 3 days in advance to your original delivery date.",
      isOpen: false,
    },
    {
      question: "What time will my furniture arrive?",
      answer:
        "We offer available delivery dates every Friday-Sunday between the hours 8am-10pm. On the day of delivery, we will call 30 minutes beforehand to let you know of our arrival.",
      isOpen: false,
    },
    {
      question: "When will my furniture be picked up?",
      answer:
        "We offer available pick-up dates every Friday-Sunday between the hours 8am-10pm. On the day of pick-up, we will call 30 minutes beforehand to let you know of our arrival.",
      isOpen: false,
    },
    {
      question: "What happens if my furniture breaks?",
      answer:
        "We understand that accidents happen, so we cover 35% of the cost to replace the furniture. File a furniture replacement and pay any necessary fees, and we'll have your replacement furniture on the way.",
      isOpen: false,
    },
    {
      question: "Is my furniture always new?",
      answer:
        "All of our items will be delivered in like-new or new conditions. Before any furniture is rented, it is first cleaned and refurbished by in-house experts to maintain a consistent quality on all items ensuring every customer enjoys the same great experience.",
      isOpen: false,
    },
    {
      question: "How far in advance do I have to place my order?",
      answer:
        "Orders must be placed at least 10 days in advance of scheduled delivery date.",
      isOpen: false,
    },
  ]);

  const toggleClickHandler = (faqIndex) => {
    const tempFaqs = [...faqs];
    tempFaqs[faqIndex].isOpen = !tempFaqs[faqIndex].isOpen;
    setFaqs(tempFaqs);
  };

  return (
    <section ref={innerRef} className="app-faq overflow-bg maxwidth">
      <h3 className="left">FAQ</h3>
      <div className="right">
        <div className="category">
          <ul className="questions open">
            {faqs.map((faq, index) => (
              <li
                key={index}
                data-slug="what-is-kaiyo"
                className={faq?.isOpen ? "open" : ""}
              >
                <h5>
                  {faq.question}

                  <img
                    onClick={() => toggleClickHandler(index)}
                    src={plusImage}
                    className="toggle"
                  />
                </h5>
                <div className="answer">
                  <p>{faq.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
