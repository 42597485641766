import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../redux";
import { Link, useNavigate } from "react-router-dom";

export function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    errorMessage,
    data: userData,
  } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    setError("");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: ACTIONS.LOGIN_SAGA,
      payload: {
        email,
        password,
        type: "ADMIN",
      },
    });
  };

  useEffect(() => {
    if (isLoggedIn && userData.type === "ADMIN") {
      navigate("/admin/products");
    }
  }, [isLoggedIn, userData, navigate]);

  return (
    <div className="view-login container fill">
      <div className="left">
        <h3>Welcome Admin!</h3>
        <form className="login" id="login-form" onSubmit={handleSubmit}>
          <label className="fixed" htmlFor="email">
            Email
          </label>
          <input
            className="input box"
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
          />
          <label className="fixed" htmlFor="password">
            Password
          </label>
          <input
            className="input box"
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="margin-top-30 error">
            <p>{error}</p>
          </div>

          <input
            className="button black-bg white"
            id="login_submit"
            name="login_submit"
            type="submit"
            value="Login"
          />
        </form>
      </div>
    </div>
  );
}
