import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
  select,
} from "redux-saga/effects";
import {
  ADD_ITEM_TO_CART_SAGA,
  DECREMENT_QTY_CART_SAGA,
  INCREMENT_QTY_CART_SAGA,
  LOAD_PRODUCTS_FOR_PACKAGE_SAGA,
  LOAD_ADDITIONAL_PRODUCTS_FOR_PACKAGE_SAGA,
  REMOVE_ITEM_FROM_CART_SAGA,
  UPDATE_CART_SAGA,
  CLEAR_CART_SAGA,
} from "../sagaActions";
import { addToast } from "../toasts/toasts.slice";
import {
  addProduct,
  changeLeaseTerm,
  changePackage,
  changeQuantity,
  changeZipCode,
  decrementQuantity,
  incrementQuantity,
  removeProduct,
  clearItemsInCart,
  updateErrorMessage,
  clearErrorMessage,
} from "./cart.slice";
import {
  initializationStartAction,
  initializationEndAction,
  saveProductsAction,
  saveAdditionalProductsAction,
} from "../studentpackages/studentpackages.slice";
import { StudentPackagesApis } from "../studentpackages/studentpackages.api";

const getFilteredProducts = (products) =>
  products
    .map((product) => {
      const _product = JSON.parse(JSON.stringify({ ...product }));
      _product.imgs = [...product.imgs];
      _product.imgs = _product.imgs.map(
        (e) =>
          (process.env.REACT_APP_S3_BASE_URL ||
            "https://production-furnitrade-data.s3.us-east-2.amazonaws.com/") +
          e
      );

      _product.img = _product.imgs[0];
      return _product;
    })
    .filter((product) => !product?.archive);

function* updateProductsInStidentPackages({ payload }) {
  yield put(clearErrorMessage());

  const { cart, studentPackages } = yield select();
  const { products } = studentPackages;

  const { items } = cart;

  const { packageId } = payload;
  //clear cart if item in cart is not of selected package
  if (
    items.length &&
    products.find((product) => product._id === items[0].id)?.studentPackage
      ?._id != packageId
  ) {
    yield put(clearItemsInCart());
  }
  yield put(initializationStartAction());
  const productRes = yield call(StudentPackagesApis.fetchProducts, packageId);

  if (productRes.status !== 200) {
    yield put(
      addToast({
        message: "products get using packageId failed",
      })
    );
    return;
  }
  yield put(saveProductsAction(getFilteredProducts(productRes.data)));
  yield put(initializationEndAction());
}

function* updateAdditionalProductsInStidentPackages() {
  yield put(clearErrorMessage());

  const { studentPackages, cart } = yield select();
  const { packages } = studentPackages;

  const selectedParentPackage = packages.find(
    (_package) => _package._id === cart.packageId
  )?.parentStudentPackage;
  const packageId = packages.find(
    (spackage) =>
      spackage?.additional &&
      spackage.parentStudentPackage === selectedParentPackage
  )?._id;

  if (!packageId) {
    yield put(
      addToast({
        message: "additional Package not found",
      })
    );
    return;
  }

  yield put(initializationStartAction());
  const productRes = yield call(
    StudentPackagesApis.fetchAdditionalProducts,
    packageId
  );

  if (productRes.status !== 200) {
    yield put(
      addToast({
        message: "additionalProducts get using packageId failed",
      })
    );
    return;
  }
  yield put(saveAdditionalProductsAction(getFilteredProducts(productRes.data)));
  yield put(initializationEndAction());
}

function* updateCartFunction({ payload }) {
  yield put(clearErrorMessage());
  for (let key in payload) {
    switch (key) {
      case "zipCode":
        yield put(changeZipCode(payload[key]));
        break;

      case "leaseTerm":
        yield put(changeLeaseTerm(payload[key]));
        break;

      case "packageId":
        yield put(changePackage(payload[key]));
        break;

      default:
        console.error(
          `Not find this key in switch ${key} and value is`,
          payload[key]
        );
        break;
    }
  }
}

function* isIncrementQtyOrAddToCartPossible(payload, quantity = 1) {
  const { id: productId } = payload;
  const { studentPackages, cart } = yield select();
  const { packageId, items, leaseTerm } = cart;
  const { packages, products, additionalProducts } = studentPackages;
  const studentpackage = packages.find(
    (studentpackage) => studentpackage._id == packageId
  );
  let { maxItemsPerCategory } = studentpackage;

  const additionalProduct = additionalProducts.find(
    (product) => product._id === productId
  );
  if (additionalProduct) {
    const additionalProductDetails = items
      .map((item) => {
        const productDetail = additionalProducts.find(
          (product) => product._id === item.id
        );
        if (productDetail) {
          return { ...productDetail, quantity: item.quantity };
        }
        return null;
      })
      .filter((product) => product);

    const usedCredits = additionalProductDetails.reduce(
      (total, product) => total + product.storeCredit * product.quantity,
      0
    );

    const requiredAdditionalCredit = additionalProduct.storeCredit * quantity;

    return usedCredits + requiredAdditionalCredit > studentpackage.storeCredit;
  }

  if (maxItemsPerCategory) {
    const categoryId = products.find((product) => product._id == productId)
      ?.category?._id;

    if (
      ["62ca68ebc6cd83f5d19c0382", "62ca68e1c6cd83f5d19c0381"].includes(
        categoryId
      )
    ) {
      maxItemsPerCategory = 1;
    }
    const productIds = items.map((item) => item.id);

    const countOfproductsOfSameCategoryInCart = products
      .filter(
        (product) =>
          productIds.includes(product._id) && product.category._id == categoryId
      )
      .reduce(
        (sum, product) =>
          +items.find((item) => item.id == product._id).quantity + sum,
        0
      );
    return countOfproductsOfSameCategoryInCart + quantity > maxItemsPerCategory;
  }
  return true;
}

function* categoryWiseLimitReachedHandle() {
  yield put(
    updateErrorMessage(
      "You have selected enough product(s) from particular category!"
    )
  );
}

function* addToCartAsync({ payload }) {
  yield put(clearErrorMessage());
  const limitReached = yield isIncrementQtyOrAddToCartPossible(payload);
  if (limitReached) {
    yield categoryWiseLimitReachedHandle();
    return;
  }
  yield put(addProduct(payload));
}
function* removeFromCartAsync({ payload }) {
  yield put(clearErrorMessage());
  yield put(removeProduct(payload));
}
function* incrementQuantityAsync({ payload }) {
  yield put(clearErrorMessage());
  const limitReached = yield isIncrementQtyOrAddToCartPossible(payload);
  if (limitReached) {
    yield categoryWiseLimitReachedHandle();
    return;
  }
  yield put(incrementQuantity(payload));
}
function* decrementQuantityAsync({ payload }) {
  yield put(clearErrorMessage());
  yield put(decrementQuantity(payload));
}

function* clearCart() {
  yield put(clearItemsInCart());
}

function* updateCart() {
  yield takeEvery(UPDATE_CART_SAGA, updateCartFunction);
  yield takeEvery(ADD_ITEM_TO_CART_SAGA, addToCartAsync);
  yield takeEvery(REMOVE_ITEM_FROM_CART_SAGA, removeFromCartAsync);
  yield takeEvery(INCREMENT_QTY_CART_SAGA, incrementQuantityAsync);
  yield takeEvery(DECREMENT_QTY_CART_SAGA, decrementQuantityAsync);
  yield takeLatest(
    LOAD_PRODUCTS_FOR_PACKAGE_SAGA,
    updateProductsInStidentPackages
  );
  yield takeLatest(
    LOAD_ADDITIONAL_PRODUCTS_FOR_PACKAGE_SAGA,
    updateAdditionalProductsInStidentPackages
  );
  yield takeLatest(CLEAR_CART_SAGA, clearCart);
}

export const cartSagas = [fork(updateCart)];
