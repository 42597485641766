import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
} from "redux-saga/effects";
import { addTodo, fetchData } from "./todo.slice";
import { LOAD_TODO_SAGA, ADD_TODO_SAGA } from "../sagaActions";

function* onLoadTodoAsync({ payload }) {
  yield delay(5000);
  yield put(fetchData({ payload }));
}

function* onAddTodoAsync({ payload }) {
  yield delay(5000);
  yield put(addTodo({ payload }));
}

function* onLoadTodos() {
  yield takeLatest(LOAD_TODO_SAGA, onLoadTodoAsync);
}

function* onAddTodo() {
  yield takeLatest(ADD_TODO_SAGA, onAddTodoAsync);
}

export const todoSagas = [fork(onLoadTodos), fork(onAddTodo)];
