import React, { useEffect, useState } from "react";
import {
  StudentPackageStepOne as StudentPackageStepOneComponent,
  StudentPackageStepTwo as StudentPackageStepTwoComponent,
  StudentPackageStepThree as StudentPackageStepThreeComponent,
  StudentPackageStepFour as StudentPackageStepFourComponent,
} from "../components/StudentPackage";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../redux";
import { useCallback } from "react";
import { ComponentLoader } from "../components/Loader";
import { useLocation } from "react-router-dom";

const STEP_KEYS = {
  STUDENT_PACKAGE: "STUDENT_PACKAGE",
  PRODUCT_LIST: "PRODUCT_LIST",
  ADDITIONAL_PRODUCT_LIST: "ADDITIONAL_PRODUCT_LIST",
  VIEW_CART: "VIEW_CART",
};

export function StudentPackage() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { initializing } = useSelector((state) => state.studentPackages);
  const { packageId, leaseTerm, zipCode } = useSelector((state) => state.cart);

  const data = {
    packageId,
    leaseTerm,
    zipCode,
  };

  const changeHandler = useCallback(
    (e) => {
      dispatch({
        type: ACTIONS.UPDATE_CART_SAGA,
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
    [dispatch]
  );

  const STEPS = {
    [STEP_KEYS.STUDENT_PACKAGE]: {
      key: STEP_KEYS.STUDENT_PACKAGE,
      Component: StudentPackageStepOneComponent,
      props: {
        data,
        changeHandler,
        goToNextPage: (pid) => {
          dispatch({
            type: ACTIONS.LOAD_PRODUCTS_FOR_PACKAGE_SAGA,
            payload: {
              packageId: pid || packageId,
            },
          });
          changeStep(STEP_KEYS.PRODUCT_LIST);
        },
      },
    },
    [STEP_KEYS.PRODUCT_LIST]: {
      key: STEP_KEYS.PRODUCT_LIST,
      Component: StudentPackageStepTwoComponent,
      props: {
        data,
        changeHandler,
        goToPreviousPage: () => {
          changeStep(STEP_KEYS.STUDENT_PACKAGE);
        },
        goToNextPage: () => {
          dispatch({
            type: ACTIONS.LOAD_ADDITIONAL_PRODUCTS_FOR_PACKAGE_SAGA,
          });
          changeStep(STEP_KEYS.ADDITIONAL_PRODUCT_LIST);
        },
      },
    },
    [STEP_KEYS.ADDITIONAL_PRODUCT_LIST]: {
      key: STEP_KEYS.ADDITIONAL_PRODUCT_LIST,
      Component: StudentPackageStepThreeComponent,
      props: {
        data,
        changeHandler,
        goToPreviousPage: () => {
          changeStep(STEP_KEYS.PRODUCT_LIST);
        },
        goToNextPage: () => {
          changeStep(STEP_KEYS.VIEW_CART);
        },
      },
    },
    [STEP_KEYS.VIEW_CART]: {
      key: STEP_KEYS.VIEW_CART,
      Component: StudentPackageStepFourComponent,
      props: {
        data,
        goToPreviousPage: () => {
          changeStep(STEP_KEYS.ADDITIONAL_PRODUCT_LIST);
        },
      },
    },
  };

  const [currentStep, setCurrentStep] = useState(STEPS.STUDENT_PACKAGE.key);

  function changeStep(key) {
    setCurrentStep(key);
  }

  useEffect(() => {
    //load initial datas to reducer
    dispatch({ type: ACTIONS.LOAD_INITIALS_SP_SAGA });
  }, []);

  useEffect(() => {
    const step = new URLSearchParams(location.search).get("step");
    const keys = Object.values(STEP_KEYS);
    if (!isNaN(step) && step > 0 && step <= keys.length) {
      setCurrentStep(keys[+step - 1]);
    }
  }, [location.search]);

  const { Component, props: componentProps } = STEPS[currentStep];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <>
      <Component {...componentProps} />
      {initializing && <ComponentLoader />}
    </>
  );
}
