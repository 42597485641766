import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  affirmLogo,
  bedImage,
  calendarImage,
  deskImage,
  packageImage,
  popupImage,
  truckImage,
} from "../../assets";
import { LeaseTerms } from "../../constants";
import { Modal } from "../Modal/Modal";
import { Review } from "../Review";

export function StudentPackageStepOne(props) {
  const { data, changeHandler, goToNextPage } = props;
  const { zipCode, leaseTerm } = data;
  const { parentPackages, packages, zipcodes } = useSelector(
    (state) => state.studentPackages
  );
  const [currentParentPackage, setCurrentParentPackage] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [isModelDataFilled, setIsModelDataFilled] = useState(false);
  const [currentParentPackagePrices, setCurrentParentPackagePrices] = useState(
    {}
  );
  const [
    currentParentPackageAffirmPrices,
    setCurrentParentPackageAffirmPrices,
  ] = useState({});

  const packagesRef = useRef();

  useEffect(() => {
    if (showModel && leaseTerm) {
      setIsModelDataFilled(true);

      const prices = {};
      const affirmPrices = {};
      parentPackages.forEach((parentPackage, index) => {
        prices[`${parentPackage._id}`] = [];
        affirmPrices[`${parentPackage._id}`] = [];

        packages.forEach((sp) => {
          if (
            sp.parentStudentPackage === parentPackage._id &&
            !sp.additional &&
            sp.variants?.[leaseTerm]
          ) {
            prices[`${parentPackage._id}`].push(sp.variants[leaseTerm]);
          }

          if (
            sp.parentStudentPackage === parentPackage._id &&
            !sp.additional &&
            sp.affirmVariants?.[leaseTerm]
          ) {
            affirmPrices[`${parentPackage._id}`].push(
              sp.affirmVariants[leaseTerm]
            );
          }
        });
      });

      setCurrentParentPackagePrices(prices);
      setCurrentParentPackageAffirmPrices(affirmPrices);
    }
  }, [
    showModel,
    leaseTerm,
    packages,
    parentPackages,
    setCurrentParentPackagePrices,
    setCurrentParentPackageAffirmPrices,
  ]);

  useEffect(() => {
    if (!leaseTerm || !zipCode) {
      setIsModelDataFilled(false);
    }
  }, [leaseTerm, zipCode]);

  const LeaseTermsOptions = useMemo(
    () =>
      LeaseTerms.map((lt) => (
        <option key={lt.value} value={lt.value}>
          {lt.label}
        </option>
      )),
    []
  );

  const handleNext = useCallback(
    (sp) => {
      changeHandler({
        target: {
          name: "packageId",
          value: sp._id,
        },
      });
      if (!zipcodes.filter((e) => e === zipCode).length) {
        return;
      }
      if (!leaseTerm) {
        return;
      }

      goToNextPage(sp._id);
    },
    [zipCode, leaseTerm, zipcodes, goToNextPage, changeHandler]
  );

  return (
    <>
      <div className="packages-div white-bg">
        <section className="padding-top-50 padding-bottom-50 maxwidth">
          <h3>Furniture Packages</h3>

          <div className="student-package-grid parent-package">
            {parentPackages.map((parentPackage) => (
              <div className="package" key={parentPackage._id}>
                <div
                  className="name"
                  style={{ backgroundColor: parentPackage.color }}
                >
                  <b>{parentPackage.name}</b>
                </div>
                <div className="details parent-package">
                  <div className="starting-at-price">
                    {isModelDataFilled && leaseTerm && zipCode ? (
                      <div className="parent-package-pricing">
                        {" "}
                        As low as
                        <h5>
                          {
                            <>
                              {` $${
                                currentParentPackageAffirmPrices[
                                  parentPackage._id
                                ][0]
                              } `}
                              /mo
                            </>
                          }
                        </h5>{" "}
                        with <img src={affirmLogo} alt="affirm-logo" />
                      </div>
                    ) : (
                      <div className="parent-package-pricing">
                        As low as <h5>${parentPackage.affirmPrice}/mo</h5> with{" "}
                        <img src={affirmLogo} alt="affirm-logo" />
                      </div>
                    )}

                    <p className="or-text">or</p>

                    {isModelDataFilled && leaseTerm && zipCode ? (
                      <div className="parent-package-pricing">
                        <b>one-time payment</b> starting at $
                        {
                          <b>
                            {currentParentPackagePrices[parentPackage._id][0]}
                            <span>
                              /{" "}
                              {
                                LeaseTerms.filter(
                                  (lt) => lt.value === leaseTerm
                                )[0].months
                              }{" "}
                              mo
                            </span>
                          </b>
                        }
                      </div>
                    ) : (
                      <div className="parent-package-pricing">
                        <b>one-time payment</b> starting at $
                        <b>{parentPackage.price}</b>
                      </div>
                    )}
                  </div>

                  <p className="subtitle margin-bottom-10">
                    {parentPackage.description}
                  </p>
                </div>
                <div className="button-div">
                  <button
                    onClick={() => {
                      setCurrentParentPackage(parentPackage);
                      setShowModel(true);
                    }}
                  >
                    {isModelDataFilled ? "Change Lease" : "View Details"}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <h5 className="parent-package-title" ref={packagesRef}>
            {currentParentPackage.name}
          </h5>
          <div className="student-package-grid">
            {packages
              .filter(
                (sp) =>
                  !sp.additional &&
                  sp.parentStudentPackage === currentParentPackage._id
              )
              .map((sp) => (
                <div className="package" key={sp._id}>
                  <div
                    className="name"
                    style={{ backgroundColor: currentParentPackage.color }}
                  >
                    <b>{sp.name}</b>
                  </div>
                  <div className="details">
                    <div className="sub-details">
                      <div>
                        <p className="subtitle">
                          <b>Primary items</b>
                        </p>
                        <p className="capitalize">{sp.description}</p>
                      </div>

                      <div>
                        <p className="subtitle">
                          <b>Secondary items</b>
                        </p>
                        <p>{sp.storeCredit} credits</p>
                      </div>
                    </div>

                    <div className="price">
                      <div className="pricing-text">
                        <p>
                          Through{" "}
                          <img
                            className="affirm-text"
                            src={affirmLogo}
                            alt="affirm-logo"
                          />
                        </p>
                        {isModelDataFilled && leaseTerm && zipCode
                          ? `$${sp?.affirmVariants?.[leaseTerm]}/mo`
                          : ""}
                      </div>

                      <span className="pricing-slash">/</span>

                      <div className="pricing-text">
                        <p>One-time payment</p>
                        {isModelDataFilled && leaseTerm && zipCode
                          ? `$${sp.variants[leaseTerm]}`
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="button-div">
                    <button
                      onClick={() => {
                        if (!isModelDataFilled) {
                          setShowModel(true);
                        } else {
                          handleNext(sp);
                        }
                      }}
                    >
                      {isModelDataFilled ? "Customize" : "View Details"}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
      <div className="how-it-works">
        <section className="padding-top-50 padding-bottom-50 maxwidth">
          <h3>How it works</h3>
          <div className="row-grid">
            <div>
              <img src={packageImage} alt={"choose-lease-term-and-package"} />
              <br />
              <p className="title">
                <b>Choose Lease Term & Package</b>
              </p>
              <p className="description">
                Furnitrade offers furniture packages suitable for groups as
                small as one person and up to 5 total roommates in 3 - 12 month
                leases.
              </p>
            </div>
            <div>
              <img src={bedImage} alt={"pick-included-items"} />
              <br />
              <p className="title">
                <b>Pick Primary items</b>
              </p>
              <p className="description">
                All packages include the basic living necessities for each
                roommate.
              </p>
            </div>
            <div>
              <img src={deskImage} alt={"pick-additional-items"} />
              <br />
              <p className="title">
                <b>Pick Secondary Items</b>
              </p>
              <p className="description">
                All packages include store credits that can be used to lease any
                additional item. Freely decorate your living space without any
                long-term commitments.
              </p>
            </div>
            <div>
              <img src={calendarImage} alt={"delivery-and-pickup"} />
              <br />
              <p className="title">
                <b>Schedule delivery & pickup</b>
              </p>
              <p className="description">
                Once you schedule your delivery and pickup, our team will come
                and set up your furniture at no additional cost.
              </p>
            </div>
          </div>
          <Link to={"/howitworks"}>
            <button className="learn-more">Learn more</button>
          </Link>
        </section>
      </div>
      <div className="delivery white-bg">
        <section className="padding-top-50 padding-bottom-50 maxwidth">
          <img src={truckImage} alt={"delivery-truck"} />
          <p className="title">
            <b>
              Furnitrade provides free delivery, installation, and pickup on all
              furniture package rentals
            </b>
          </p>
          <p className="description">
            We'll do all the heavy lifting and hard work, so kick back, relax
            and watch as your living space comes to life
          </p>
        </section>
      </div>
      <Review />
      <Modal modalVisible={showModel} setModalVisible={setShowModel}>
        <div className="check-delivery-modal">
          <div className="left">
            <img src={popupImage} alt={"check-delivery"} />
          </div>

          <div className="right">
            <h3>Based in Los Angeles</h3>

            <p>
              Enter your delivery ZIP code to view Furnitrade's pricing and
              delivery availability
            </p>

            <div className="select-cover">
              <div>
                <label>Zip Code</label>
                <select
                  className="select"
                  name="zipCode"
                  value={zipCode}
                  onChange={changeHandler}
                >
                  <option value="">Select Zip Code</option>
                  {zipcodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="fixed" htmlFor="email">
                  Lease Term
                </label>
                <select
                  className="select"
                  name="leaseTerm"
                  value={leaseTerm}
                  onChange={changeHandler}
                >
                  <option value="">Select Lease Term</option>
                  {LeaseTermsOptions}
                </select>
              </div>
            </div>

            <button
              onClick={() => {
                setShowModel(false);
                window.scrollTo({
                  behavior: "smooth",
                  top: packagesRef.current.offsetTop - 100,
                });
              }}
              style={{ background: "#7aa062" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
