import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { ACTIONS } from "../../redux";
import { Link, useNavigate } from "react-router-dom";

export function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isLoggedIn, errorMessage } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    setError("");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: ACTIONS.SIGNUP_SAGA, payload: { email, password } });
  };

  if (isLoggedIn) {
    navigate("/");
  }

  return (
    <div className="view-login container fill">
      <div className="left">
        <h3>New Customers</h3>
        <form className="login" id="login-form" onSubmit={handleSubmit}>
          <label className="fixed" htmlFor="email">
            Email
          </label>
          <input
            className="input box"
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
          />
          <label className="fixed" htmlFor="password">
            Password
          </label>
          <input
            className="input box"
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="button black-bg white"
            id="login_submit"
            name="login_submit"
            type="submit"
            value="Register"
          />

          <div className="margin-top-30 error">
            <p>{error}</p>
          </div>

          <div className="login-signup-link">
            <Link to={"/login"}>Already have an account?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
