import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
} from "redux-saga/effects";
import { LOAD_INITIALS_SP_SAGA } from "../sagaActions";
import { addToast } from "../toasts/toasts.slice";
import { StudentPackagesApis } from "./studentpackages.api";
import {
  initializationEndAction,
  initializationStartAction,
  saveParentPackagesAction,
  savePackagesAction,
  saveZipcodesAction,
} from "./studentpackages.slice";

function* handleError(response) {
  yield put(
    addToast({
      message: "Error in fetchinng data",
    })
  );
}

function* initialiseZipcodes() {
  const response = yield StudentPackagesApis.fetchZipcodes();
  if (response.status !== 200) {
    yield call(handleError, response);
    return;
  }
  const zipCodes = response.data.zipCodes;
  yield put(saveZipcodesAction(zipCodes));
}

function* initialisePackages() {
  const response = yield StudentPackagesApis.fetchStudentPackages();
  if (response.status !== 200) {
    yield call(handleError, response);
    return;
  }
  const { parentStudentPackages, studentPackages } = response.data;
  yield put(saveParentPackagesAction(parentStudentPackages));
  yield put(savePackagesAction(studentPackages));
}

function* loadInitialsAsync() {
  yield put(initializationStartAction());
  yield call(initialiseZipcodes);
  yield call(initialisePackages);
  yield put(initializationEndAction());
}

function* loadInitials() {
  yield takeLatest(LOAD_INITIALS_SP_SAGA, loadInitialsAsync);
}

export const studentPackaesSagas = [fork(loadInitials)];
