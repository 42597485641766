import React, { useEffect } from "react";
import { useState } from "react";
import { getBasicClient } from "../../services";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

export function StudentPackageList() {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  const client = getBasicClient();

  const fetchData = async () => {
    const data = await client.get("/api/studentpackage");

    const tempParentPackages = {};
    data.data.parentStudentPackages.forEach((parentPackage) => {
      tempParentPackages[parentPackage._id.toString()] = parentPackage;
    });

    let packages = data.data.studentPackages.filter(
      (packageObj) => packageObj.name !== "Additional Package"
    );
    packages.forEach((_package) => {
      _package["parentStudentPackage"] =
        tempParentPackages[_package.parentStudentPackage];
    });
    packages.sort((a, b) =>
      a?.parentStudentPackage?.name.localeCompare(b?.parentStudentPackage?.name)
    );

    setPackages(packages);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Parent Package Name",
      selector: (row) => row?.parentStudentPackage?.name,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Store Credit",
      selector: (row) => row.storeCredit,
    },
    {
      name: "Max Items Per Category",
      selector: (row) => row.maxItemsPerCategory,
    },
    {
      name: "Price Range",
      selector: (row) =>
        row.variants
          ? `$${row?.variants?.six} - $${row?.variants?.twelve}`
          : "",
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="button black-bg white"
          onClick={() => {
            navigate("/admin/create-student-packages", {
              state: { edit: true, data: row },
            });
          }}
        >
          Update
        </button>
      ),
    },
  ];

  return (
    <div className="admin-right">
      <h4>Student Packages</h4>

      <Link
        to={"/admin/create-student-packages"}
        className="button outline black create-button"
      >
        Create Student Package
      </Link>

      <DataTable columns={columns} data={packages} pagination />
    </div>
  );
}
