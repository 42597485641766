import { useEffect } from "react";
import { notFoundImage } from "../assets";

export function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <img src={notFoundImage} />
      <p>404 not found</p>
    </>
  );
}
