import React, { useEffect } from "react";
import { AdminDiscountCodeList as AdminDiscountCodeListComponent } from "../components/DiscountCode";

export function AdminDiscountCodeList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AdminDiscountCodeListComponent />;
}
