import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ACTIONS } from "../../redux";
import { logoImage } from "../../assets";

export function Header() {
  const [sticky, setSticky] = useState("");
  const [overLayOpen, setOverLayOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [usermenu, setUsermenu] = useState(false);
  const { email } = userData || {};

  useEffect(() => {
    setSticky("");

    if (location.pathname === "/") {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    } else {
      setSticky("is-sticky");
    }
  }, [location.pathname]);

  const isSticky = () => {
    setSticky(
      window.scrollY >= window.innerHeight ? "is-sticky header-transition" : ""
    );
  };

  const togggleOverLay = () => setOverLayOpen((old) => !old);

  const logoutHandler = () => {
    dispatch({ type: ACTIONS.LOGOUT_SAGA });
  };

  const toggleUsermenu = () => {
    setUsermenu(!usermenu);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.closest("#user_menu")) {
        setUsermenu(false);
      }
    });
  }, []);

  const handleNavigation = (path) => () => {
    setOverLayOpen(false);
    navigate(path);
  };

  return (
    <header className={`app-header ${sticky}`}>
      <div className="container pad">
        <Link to={"/"} onClick={handleNavigation("/")}>
          <img className="logo" src={logoImage} style={{ width: "135px" }} />
        </Link>
        <div className="hamburger" onClick={togggleOverLay}>
          <svg width={24} height={18} xmlns="http://www.w3.org/2000/svg">
            <g fill="#000000" fillRule="evenodd">
              <path d="M0 16h24v2H0z" />
              <path d="M0 0h24v2H0z" />
              <path d="M0 8h24v2H0z" />
            </g>
          </svg>
        </div>
        <nav className={`overlay main ${overLayOpen ? "open" : ""}`}>
          {/* open */}
          <div className="center">
            <img className="logo" src={logoImage} style={{ width: "135px" }} />

            <Link
              to={"/furniturepackages"}
              onClick={handleNavigation("/furniturepackages")}
              className="h3"
            >
              Furniture Packages
            </Link>
            <Link
              to={"/howitworks"}
              onClick={handleNavigation("/howitworks")}
              className="h3"
            >
              How it Works
            </Link>
            <Link
              to={"/coming-soon"}
              onClick={handleNavigation("/oming-soon")}
              className="h3"
            >
              Marketplace
            </Link>
            <Link
              to={"/social"}
              onClick={handleNavigation("/social")}
              className="h3"
            >
              Social Responsibility
            </Link>
            <Link
              to={"/furniture-replacement"}
              onClick={handleNavigation("/furniture-replacement")}
              className="h3"
            >
              Furniture Replacement
            </Link>
            {isLoggedIn ? (
              <>
                <Link
                  to="/myorder"
                  onClick={handleNavigation("/myorder")}
                  className="h3"
                >
                  My orders
                </Link>
                <a onClick={logoutHandler} className="h3">
                  Logout
                </a>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  onClick={handleNavigation("/login")}
                  className="h3"
                >
                  Login
                </Link>
              </>
            )}

            <svg
              className="close"
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              onClick={togggleOverLay}
            >
              <path
                fill="#000000"
                d="M20 2.014L17.986 0 10 7.986 2.014 0 0 2.014 7.986 10 0 17.986 2.014 20 10 12.014 17.986 20 20 17.986 12.014 10z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </nav>
        <nav className="header">
          <Link
            to={"/furniturepackages"}
            onClick={handleNavigation("/furniturepackages")}
            className="why"
          >
            Furniture Packages
          </Link>
          <Link
            to={"/howitworks"}
            onClick={handleNavigation("/howitworks")}
            className="why"
          >
            How it Works
          </Link>
          <a
            to={"/coming-soon"}
            onClick={handleNavigation("/coming-soon")}
            className="why"
          >
            Marketplace
          </a>
          <Link
            to={"/social"}
            onClick={handleNavigation("/social")}
            className="why"
          >
            Social Responsibility
          </Link>
          <Link
            to={"/furniture-replacement"}
            onClick={handleNavigation("/furniture-replacement")}
            className="why"
          >
            Furniture Replacement
          </Link>
          {!isLoggedIn ? (
            <div onClick={toggleUsermenu} className="user icon">
              <svg width={18} height={21} xmlns="http://www.w3.org/2000/svg">
                <title>Dashboard</title>
                <path
                  d="M9 9.4444444c-2.4099698 0-4.3636364-1.8903532-4.3636364-4.2222222S6.5900302 1 9 1s4.3636364 1.8903533 4.3636364 4.2222222c0 2.331869-1.9536666 4.2222222-4.3636364 4.2222222zm-8 2.8148149h16V20H1v-7.7407407z"
                  stroke="#000"
                  strokeWidth="1.6"
                  fill="none"
                  fillRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <div onClick={toggleUsermenu} className="user-profile">
              <p>{email[0].toUpperCase()}</p>
            </div>
          )}
        </nav>
        <div
          id="user_menu"
          aria-hidden="true"
          role="dialog"
          aria-labelledby="user-menu-title"
          style={usermenu ? { display: "block" } : { display: "none" }}
        >
          <h6 id="user-menu-title">Welcome!</h6>
          <div
            className="logged_in"
            aria-hidden="true"
            style={isLoggedIn ? { display: "block" } : { display: "none" }}
          >
            <div className="buttons">
              <Link
                to="/myorder"
                onClick={handleNavigation("/myorder")}
                role="button"
                className="button black-bg white"
              >
                My orders
              </Link>
              <button
                id="menuLogout"
                onClick={logoutHandler}
                role="button"
                className="button outline"
              >
                Logout
              </button>
            </div>
          </div>
          <div
            className="logged_out"
            style={!isLoggedIn ? { display: "block" } : { display: "none" }}
          >
            <div className="buttons">
              <Link
                to="/signup"
                onClick={handleNavigation("/signup")}
                role="button"
                className="button black-bg white"
              >
                Sign Up
              </Link>
              <Link
                to="/login"
                onClick={handleNavigation("/login")}
                role="button"
                className="button outline login-button"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
