import { Routes as ReactRouterRoutes, Route, Navigate } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { HomePage } from "./pages/HomePage";
import { StudentPackage } from "./pages/StudentPackage";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { Signup } from "./pages/Signup";
import {
  MainLayout,
  AdminMainLayout,
  BlankLayout,
  AdminLayout,
} from "./layouts";
import { MyOrders } from "./pages/MyOrders";
import { HowItWorks } from "./pages/HowItWorks";
import { SocialResponsibility } from "./pages/SocialResponsibility";
import { Thankyou } from "./pages/Thankyou";
import { CreateProduct } from "./pages/CreateProuduct";
import { Products } from "./pages/Prouducts";
import { AdminLogin } from "./pages/AdminLogin";
import { StudentPackageList } from "./pages/AdminStudentPackageList";
import { AdminOrderList } from "./pages/AdminOrderList";
import { StudentPackageForm } from "./pages/AdminStudentPackageForm";
import { AdminCategoryList } from "./pages/AdminCategoryList";
import { AdminCategoryForm } from "./pages/AdminCategoryForm";
import { FurnitureReplacement } from "./pages/FurnitureReplacement";
import { ComingSoon } from "./pages/ComingSoon";
import { Ambassador } from "./pages/Ambassador";
import { AdminDiscountCodeList } from "./pages/AdminDiscountCodeList";
import { AdminDiscountCodeForm } from "./pages/AdminDiscountCodeForm";

export default function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="admin" element={<BlankLayout />}>
        <Route index element={<AdminLayout />} />
        <Route path="login" element={<AdminLogin />} />
        <Route element={<AdminMainLayout />}>
          <Route path="products" element={<Products />} />
          <Route path="create-product" element={<CreateProduct />} />
          <Route path="categories" element={<AdminCategoryList />} />
          <Route path="create-category" element={<AdminCategoryForm />} />
          <Route path="student-packages" element={<StudentPackageList />} />
          <Route
            path="create-student-packages"
            element={<StudentPackageForm />}
          />
          <Route path="orders" element={<AdminOrderList />} />
          <Route path="discount-codes" element={<AdminDiscountCodeList />} />
          <Route
            path="create-discount-code"
            element={<AdminDiscountCodeForm />}
          />
        </Route>
        <Route path="*" element={<></>} />
      </Route>

      <Route path="*" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="furniturepackages" element={<StudentPackage />} />
        <Route path="howitworks" element={<HowItWorks />} />
        <Route path="social" element={<SocialResponsibility />} />
        <Route
          path="furniture-replacement"
          element={<FurnitureReplacement />}
        />
        <Route path="ambassador" element={<Ambassador />} />
        <Route path="myorder" element={<MyOrders />} />
        <Route path="thankyou" element={<Thankyou />} />
        <Route path="coming-soon" element={<ComingSoon />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot-password">
          <Route index element={<ForgotPassword />} />
          <Route path=":token" element={<ResetPassword />} />
        </Route>
        <Route path="*" element={<Navigate to={"/404"} replace />} />
      </Route>
      <Route path="404" element={<NotFound />} />
    </ReactRouterRoutes>
  );
}
