import React, { useEffect } from "react";
import { Products as ProductsComponent } from "../components/Product";

export function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <ProductsComponent />;
}
