import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../redux";
import { deleteImage } from "../../assets";
import { ProductCard } from "../Product/ProductCard";

export function StudentPackageStepThree(props) {
  const { goToPreviousPage, data, goToNextPage } = props;
  const { packageId, leaseTerm } = data;

  const { additionalProducts: products, packages } = useSelector(
    (state) => state.studentPackages
  );
  const { items, errorMessage } = useSelector((state) => state.cart);

  const getProductFromCart = (productId) => {
    const product = items.find((item) => item.id == productId);
    return product
      ? { ...product, exists: true }
      : {
          exists: false,
        };
  };
  const studentPackage = packages.find(
    (studentPackage) => studentPackage._id == packageId
  );

  const productsByCategory = [
    ...new Set(products.map((product) => product.category._id)),
  ].reduce((obj, categoryId) => {
    const filteredProducts = products.filter(
      (product) => product.category._id === categoryId
    );

    return {
      ...obj,
      [categoryId]: {
        products: filteredProducts,
        category: filteredProducts[0].category,
      },
    };
  }, {});

  const totalAvailableCredits = studentPackage.storeCredit;

  const usedCredits = items
    .map(({ id, quantity }) => {
      const product = products.find((product) => product._id === id);
      if (product) {
        return { ...product, quantity };
      }
      return null;
    })
    .filter((product) => product)
    .reduce(
      (total, product) => total + product.quantity * product.storeCredit,
      0
    );

  return (
    <>
      <div className="page-head">
        <section className="maxwidth">
          <h4 className="title">Select Secondary Items</h4>

          <div className="credit-information">
            <div>
              <div>
                <div>
                  <p>
                    <b>Available Credits:</b> {totalAvailableCredits}
                  </p>
                </div>
                <span>|</span>
                <div>
                  <p>
                    <b>Used Credits:</b> {usedCredits}
                  </p>
                </div>
              </div>
            </div>

            <div className="error margin-top-5">
              <p>{errorMessage}</p>
            </div>
          </div>

          <div className="buttons">
            <button
              className="button black-bg white"
              onClick={goToPreviousPage}
            >
              {"<"}&nbsp; Previous
            </button>
            <button className="button black-bg white" onClick={goToNextPage}>
              Next &nbsp;{">"}
            </button>
          </div>
        </section>
      </div>

      <div className="view-shop app-static-shop container fill maxwidth">
        <section className="shop-listing maxwidth">
          {Object.keys(productsByCategory).map((categoryId, index) => (
            <div key={categoryId}>
              <h4 className="category-name">
                {productsByCategory[categoryId].category.name}
              </h4>
              <div className="product-grid">
                {productsByCategory[categoryId].products.map((product) => (
                  <ProductCard
                    key={product._id}
                    product={product}
                    categoryId={categoryId}
                    cartProduct={getProductFromCart(product._id)}
                    withCredit={true}
                  />
                ))}
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
}
