import moment from "moment";

export function getNextWeekendDay() {
  const today = moment();

  const daysToAdd = (5 - today.day() + 7) % 7;
  const friday = today.clone().add(daysToAdd, "days");

  const saturday = friday.clone().add(1, "day");
  const sunday = friday.clone().add(2, "days");

  const nearestDay = [friday, saturday, sunday].reduce((nearest, current) => {
    if (!nearest || current.diff(today) < nearest.diff(today)) {
      return current;
    }
    return nearest;
  });

  return nearestDay;
}

export function getNearestPastWeekendDay(inputDate) {
  const today = moment(inputDate);

  if (today.day() >= 5) {
    return today;
  }

  const daysToSubtract = today.day() + 2;
  const sunday = today.clone().subtract(daysToSubtract, "days");

  const saturday = sunday.clone().add(1, "day");
  const friday = sunday.clone().add(2, "days");

  const nearestDay = [friday, saturday, sunday].reduce((nearest, current) => {
    if (!nearest || today.diff(current) < today.diff(nearest)) {
      return current;
    }
    return nearest;
  });

  return nearestDay;
}
