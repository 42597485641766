import React, { useEffect } from "react";
import { AdminCategoryList as AdminCategoryListComponent } from "../components/Category";

export function AdminCategoryList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AdminCategoryListComponent />;
}
