import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zipCode: "",
  leaseTerm: "",
  packageId: "",
  items: [],
  errorMessage: "",
};

const userSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    changeZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    changeLeaseTerm: (state, action) => {
      state.leaseTerm = action.payload;
    },
    changePackage: (state, action) => {
      state.packageId = action.payload;
    },
    addProduct: (state, action) => {
      const { id } = action.payload;
      const found = state.items.find((item) => item.id == id);
      if (!found) {
        state.items.push({ id, quantity: 1 });
      }
    },
    removeProduct: (state, action) => {
      const { id: productId } = action.payload;
      state.items = state.items.filter(({ id }) => id != productId);
    },
    incrementQuantity: (state, action) => {
      const { id: productId } = action.payload;
      state.items = state.items.map((product) => {
        if (product.id == productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });
    },
    decrementQuantity: (state, action) => {
      const { id: productId } = action.payload;
      state.items = state.items
        .map((product) => {
          if (product.id == productId) {
            if (product.quantity > 1) {
              return {
                ...product,
                quantity: product.quantity - 1,
              };
            }
            return null;
          }
          return product;
        })
        .filter((item) => item);
    },
    changeQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      state.items = state.items.map((product) => {
        if (product.id == id) {
          return {
            ...product,
            quantity,
          };
        }
        return product;
      });
    },
    clearItemsInCart: (state) => {
      state.items = [];
    },
    updateErrorMessage: (state, action) => {
      const message = action.payload;
      state.errorMessage = message;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = "";
    },
  },
});

export const {
  changeZipCode,
  changeLeaseTerm,
  changePackage,
  addProduct,
  removeProduct,
  incrementQuantity,
  decrementQuantity,
  changeQuantity,
  clearItemsInCart,
  updateErrorMessage,
  clearErrorMessage,
} = userSlice.actions;

export default userSlice.reducer;
