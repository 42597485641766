import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LeaseTerms } from "../../constants";
import { getAPIClient, getBasicClient } from "../../services";

export function CategoryForm() {
  const [data, setData] = useState({ name: "" });
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const client = getAPIClient();
  const location = useLocation();

  const [iseditMode, setIseditMode] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { edit, data: categoryData } = location.state;
      if (edit !== undefined) {
        setIseditMode(edit);
      }
      if (categoryData !== undefined) {
        const _data = JSON.parse(JSON.stringify(categoryData));
        setData(_data);
      }
    }
  }, []);

  const handleChange = (e) => {
    setSuccessMsg("");
    setError("");

    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const getInputProps = (key) => {
    let obj = { name: key, value: data[key], onChange: handleChange };
    return obj;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const _data = JSON.parse(JSON.stringify(data));

    let res;
    const id = _data._id;
    delete _data._id;
    delete _data.createdAt;
    delete _data.updatedAt;
    delete _data.__v;

    if (iseditMode) {
      res = (await client.put("/api/category/" + id, _data)).data;
    } else {
      res = (await client.post("/api/category", _data)).data;
    }

    setSuccessMsg(iseditMode ? "Updated!" : "Created!");
  };

  return (
    <div className="admin-right">
      <h4>{iseditMode ? "Update Category" : "Create Category"}</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("name")}
            required
          />
        </div>

        <div className="margin-top-10 error">
          <p>{error}</p>
        </div>
        <div className="margin-top-10 success">
          <p>{successMsg}</p>
        </div>

        <div className="form-group">
          <button type="submit" className="button black-bg white">
            {iseditMode ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
