import React, { useEffect } from "react";
import { useState } from "react";
import { getBasicClient } from "../../services";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

export function AdminCategoryList() {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  const client = getBasicClient();

  const fetchData = async () => {
    const data = await client.get("/api/category");
    const packages = data.data.filter(
      (packageObj) => packageObj.name !== "Additional Package"
    );
    setPackages(packages);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="button black-bg white"
          onClick={() => {
            navigate("/admin/create-category", {
              state: { edit: true, data: row },
            });
          }}
        >
          Update
        </button>
      ),
    },
  ];

  return (
    <div className="admin-right">
      <h4>Categories</h4>

      <Link
        to={"/admin/create-category"}
        className="button outline black create-button"
      >
        Create Category
      </Link>

      <DataTable columns={columns} data={packages} pagination />
    </div>
  );
}
