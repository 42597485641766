import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initializing: false,
  parentPackages: [],
  packages: [],
  zipcodes: [],
  products: [],
  additionalProducts: [],
};

const userSlice = createSlice({
  name: "studentpackages",
  initialState,
  reducers: {
    initializationStartAction: (state) => {
      state.initializing = true;
    },
    initializationEndAction: (state) => {
      state.initializing = false;
    },
    saveParentPackagesAction: (state, action) => {
      state.parentPackages = action.payload;
    },
    savePackagesAction: (state, action) => {
      state.packages = action.payload;
    },
    saveZipcodesAction: (state, action) => {
      state.zipcodes = action.payload;
    },
    saveProductsAction: (state, action) => {
      state.products = action.payload;
    },
    saveAdditionalProductsAction: (state, action) => {
      state.additionalProducts = action.payload;
    },
  },
});

export const {
  initializationEndAction,
  initializationStartAction,
  saveParentPackagesAction,
  savePackagesAction,
  saveZipcodesAction,
  saveProductsAction,
  saveAdditionalProductsAction,
} = userSlice.actions;

export default userSlice.reducer;
