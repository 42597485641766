import React, { useEffect } from "react";
import { DiscountCodeForm as DiscountCodeFormComponent } from "../components/DiscountCode";

export function AdminDiscountCodeForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <DiscountCodeFormComponent />;
}
