import React, { useEffect } from "react";

export function Thankyou() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="view-login container fill">
        <section className="left thankyou">
          <h1>Thanks for shopping with us.</h1>

          <p>
            Thank you for your recent purchase with Furnitrade! As it's our
            first year in business, our team is really looking forward to
            meeting all of our new customers who make Furnitrade possible.
          </p>
          <br />
          <p>
            A week prior to your chosen delivery date, we will send an email
            with available time slots that week for delivery. Please select your
            time slot to ensure you receive your furniture on your preferred
            date. Delivery will be done on a first-come, first-serve basis.
          </p>
          <br />
          <p>
            If you have any questions, please contact us on{" "}
            <b>Phone: (909) 670-2398</b> or <b>Email: order@furnitrade.biz</b>
          </p>
        </section>
      </div>
    </>
  );
}
