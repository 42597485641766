import axios from "axios";
import { TOKEN_COOKIE_KEY } from "../constants";
import { store } from "../redux";
import { ADD_TOAST_SAGA, LOGOUT_SAGA } from "../redux/sagaActions";
import { getCookie } from "../utils";

const baseURL = process.env.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL,
});

const basicClient = axios.create({
  baseURL,
});

basicClient.interceptors.response.use(
  (response) => {
    if (!response.data) {
      store.dispatch({
        type: ADD_TOAST_SAGA,
        payload: {
          message: "data from server's response not found!!",
        },
      });
    }
    return response.data;
  },
  (error) => {
    return error;
  }
);

basicClient.interceptors.request.use(function (config) {
  const token = getCookie(TOKEN_COOKIE_KEY);
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

client.interceptors.response.use(
  (response) => {
    if (!response.data) {
      store.dispatch({
        type: ADD_TOAST_SAGA,
        payload: {
          message: "data from server's response not found!!",
        },
      });
    }
    if (response.data.status === 401) {
      store.dispatch({
        type: LOGOUT_SAGA,
      });
    }
    return response.data;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch({
        type: LOGOUT_SAGA,
      });
    }
    return error;
  }
);

client.interceptors.request.use(function (config) {
  const token = getCookie(TOKEN_COOKIE_KEY);
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export function getAPIClient() {
  return client;
}

export function getBasicClient() {
  return basicClient;
}
