import {
  call,
  takeEvery,
  put,
  takeLatest,
  fork,
  delay,
  select,
} from "redux-saga/effects";
import {
  saveTokenAction,
  logoutAction,
  saveUserData,
  startChecking,
  stopChecking,
  clearErrorMessage,
  updateErrorMessage,
} from "./user.slice";
import {
  LOGIN_SAGA,
  LOGOUT_SAGA,
  SIGNUP_SAGA,
  VALIDATE_USER_SAGA,
} from "../sagaActions";
import { UserApis } from "./user.api";
import { setCookie } from "../../utils";
import { TOKEN_COOKIE_KEY } from "../../constants";
import { ACTIONS } from "../";
import { addToast } from "../toasts/toasts.slice";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils/cookie";

function* loginAsync({ payload }) {
  yield put(clearErrorMessage());
  const { email, password, type } = payload;
  let loginRes;

  loginRes = yield UserApis.login(email, password, type);
  if (loginRes.status !== 200) {
    // yield put(
    //   addToast({
    //     message: loginRes?.data?.message,
    //   })
    // );
    yield put(updateErrorMessage(loginRes?.data?.message));

    return;
  }
  const token = loginRes.data.token;
  yield put(saveTokenAction(token));
  setCookie(TOKEN_COOKIE_KEY, token);
  // yield put(
  //   addToast({
  //     message: "Login successfull",
  //     timeout: 1000,
  //   })
  // );
  yield put({
    type: VALIDATE_USER_SAGA,
  });
}

function* signupAsync({ payload }) {
  yield put(clearErrorMessage());
  const data = payload;
  const signupRes = yield UserApis.signup(data);
  if (signupRes.status === 201) {
    yield loginAsync({ payload });
  } else {
    // yield put(addToast({
    //   message:signupRes?.data?.message
    // }))
    yield put(updateErrorMessage(signupRes?.data?.message));
  }
}

function* validateUserAsync() {
  yield put(clearErrorMessage());
  yield put(startChecking());
  // const authRes = yield UserApis.fetchAuthData();
  const authRes = yield UserApis.getAuthStatusWithBasicClient();
  const data = authRes.data;
  if (authRes.status === 200) {
    yield put(saveUserData(data));
    yield put(stopChecking());
  }
}
function* logoutAsync() {
  const { user } = yield select();

  yield put(clearErrorMessage());
  //cleanup login
  yield removeCookie(TOKEN_COOKIE_KEY);
  yield localStorage.clear();

  yield put(logoutAction());
  // yield put(
  //   addToast({
  //     message: "Unanthenticated",
  //   })
  // );
  window.location.href = user.data.type === "ADMIN" ? "/admin/login" : "/login";
}

function* logout() {
  yield takeLatest(LOGOUT_SAGA, logoutAsync);
}

function* login() {
  yield takeLatest(LOGIN_SAGA, loginAsync);
}

function* register() {
  yield takeLatest(SIGNUP_SAGA, signupAsync);
}

function* validateUser() {
  yield takeLatest(VALIDATE_USER_SAGA, validateUserAsync);
}

export const userSagas = [
  fork(login),
  fork(logout),
  fork(register),
  fork(validateUser),
];
