export const LeaseTerms = [
  { value: "three", label: "3 Months", months: 3 },
  { value: "four", label: "4 Months", months: 4 },
  { value: "five", label: "5 Months", months: 5 },
  { value: "six", label: "6 Months", months: 6 },
  { value: "seven", label: "7 Months", months: 7 },
  { value: "eight", label: "8 Months", months: 8 },
  { value: "nine", label: "9 Months", months: 9 },
  { value: "ten", label: "10 Months", months: 10 },
  { value: "elevan", label: "11 Months", months: 11 },
  { value: "twelve", label: "12 Months", months: 12 },
];
