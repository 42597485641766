import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LeaseTerms } from "../../constants";
import { getAPIClient } from "../../services";

export function StudentPackageForm() {
  const [data, setData] = useState({ name: "", maxItemsPerCategory: 1 });
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const client = getAPIClient();
  const location = useLocation();

  const [iseditMode, setIseditMode] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { edit, data: packageData } = location.state;
      if (edit !== undefined) {
        setIseditMode(edit);
      }
      if (packageData !== undefined) {
        const _data = JSON.parse(JSON.stringify(packageData));

        Object.keys(_data?.variants || {}).forEach((k) => {
          _data[k] = _data.variants[k];
        });

        Object.keys(_data?.affirmVariants || {}).forEach((k) => {
          _data[`affirm_${k}`] = _data.affirmVariants[k];
        });

        setData(_data);
      }
    }
  }, [location.state]);

  const handleChange = (e) => {
    setSuccessMsg("");
    setError("");

    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const getInputProps = (key) => {
    let obj = { name: key, value: data[key], onChange: handleChange };

    console.log("inputProps", key, obj);

    return obj;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const _data = JSON.parse(JSON.stringify(data));

    let res;
    const id = _data._id;
    delete _data._id;
    delete _data.createdAt;
    delete _data.updatedAt;

    if (!_data.variants) {
      _data.variants = {};
    }
    if (!_data.affirmVariants) {
      _data.affirmVariants = {};
    }

    LeaseTerms.forEach((e) => {
      _data.variants[e.value] = +_data[e.value];
      _data.affirmVariants[e.value] = +_data[`affirm_${e.value}`];

      delete _data[e.value];
      delete _data[`affirm_${e.value}`];
    });
    if (iseditMode) {
      res = (await client.put("/api/studentpackage/" + id, _data)).data;
    } else {
      res = (await client.post("/api/studentpackage", _data)).data;
    }

    setSuccessMsg(iseditMode ? "Updated!" : "Created!");
  };

  return (
    <div className="admin-right">
      <h4>
        {iseditMode ? "Update Student Package" : "Create Student Package"}
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("name")}
            required
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("description")}
            required
          />
        </div>
        <div className="form-group">
          <label>Maximum items per category</label>
          <input
            type="number"
            className="input box"
            {...getInputProps("maxItemsPerCategory")}
            required
          />
        </div>
        <div className="form-group">
          <label>Store Credit</label>
          <input
            type="number"
            className="input box"
            {...getInputProps("storeCredit")}
            required
          />
        </div>

        {LeaseTerms.map((item) => (
          <div className="form-group" key={item.months}>
            <label>Lease term variants for {item.value} months (price)</label>
            <input
              type="number"
              className="input box"
              placeholder="0"
              {...getInputProps(item.value)}
              required
            />
          </div>
        ))}

        <br />
        <hr />

        {LeaseTerms.map((item) => (
          <div className="form-group" key={item.months}>
            <label>
              Lease term variants for {item.value} months (Affirm price)
            </label>
            <input
              type="number"
              className="input box"
              placeholder="0"
              {...getInputProps(`affirm_${item.value}`)}
              required
            />
          </div>
        ))}

        <div className="margin-top-10 error">
          <p>{error}</p>
        </div>
        <div className="margin-top-10 success">
          <p>{successMsg}</p>
        </div>

        <div className="form-group">
          <button type="submit" className="button black-bg white">
            {iseditMode ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
