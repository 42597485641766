import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { FullScreenLoader } from "../components/Loader";
import { Toast } from "../components/Toast";
import { HOMEPAGE_REDIRECT_TO_URL } from "../constants";
import { getCookie } from "../utils";
import { removeCookie } from "../utils/cookie";

export function MainLayout() {
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    const redirect_url = getCookie(HOMEPAGE_REDIRECT_TO_URL);

    if (isLoggedIn && redirect_url) {
      removeCookie(HOMEPAGE_REDIRECT_TO_URL);
      window.location.href = redirect_url;
    }
  }, [isLoggedIn]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <FullScreenLoader />
      <Toast />
    </>
  );
}
