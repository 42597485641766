import { createSlice } from "@reduxjs/toolkit";
import { ToastTypes } from "../../constants";

const defaultToastObject = {
  id: Date.now(),
  visible: false,
  message: "",
  type: ToastTypes.INFO,
  timeout: 3000,
};

const initialState = {
  items: [],
};

const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast: (state, action) => {
      let toast = action.payload;
      let createdToast = { ...defaultToastObject };

      for (let key in toast) {
        createdToast[key] = toast[key];
      }

      createdToast.visible = true;
      createdToast.id = Date.now();

      state.items.push(createdToast);
    },
    removeToast: (state, action) => {
      const id = action.payload;
      state.items = state.items.filter((e) => e.id !== id);
    },
  },
});

export const { addToast, removeToast } = toastsSlice.actions;
export default toastsSlice.reducer;
