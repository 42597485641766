import React, { useEffect } from "react";
import { useState } from "react";
import { getAPIClient, getBasicClient } from "../../services";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../utils";

export function Products() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [packages, setPackages] = useState([]);
  const [filter, setFilter] = useState({});
  const apiClient = getAPIClient();

  const navigate = useNavigate();

  const client = getBasicClient();

  const fetchData = async () => {
    const data = await client.get("/api/product");
    return data.data;
  };

  const refreshPackagesAndcategory = async () => {
    const { data: categoryData } = await client.get("/api/category");
    const {
      data: { studentPackages, parentStudentPackages },
    } = await client.get("/api/studentpackage");
    setCategory(categoryData);

    const tempParentPackages = {};
    parentStudentPackages.forEach((parentPackage) => {
      tempParentPackages[parentPackage._id.toString()] = parentPackage;
    });

    studentPackages.forEach((_package) => {
      _package["parentStudentPackage"] =
        tempParentPackages[_package.parentStudentPackage];
    });
    studentPackages.sort((a, b) =>
      a?.parentStudentPackage?.name.localeCompare(b?.parentStudentPackage?.name)
    );

    setPackages(studentPackages);
  };

  const refeshProducts = async () => {
    const _products = await fetchData();
    setProducts(_products);
    setFilteredProducts(_products);
    const __category = getCookie("__category");
    const __package = getCookie("__package");
    if (__category) {
      filterProducts("cartegory", __category);
    }
    if (__package) {
      filterProducts("package", __package);
    }
  };
  useEffect(() => {
    refreshPackagesAndcategory();
    refeshProducts();
  }, []);

  const filterProducts = (key, value) => {
    setFilter((filter) => {
      const _filter = { ...filter };
      if (["cartegory", "package"].includes(key) && value === "All") {
        delete _filter[key];
      } else {
        _filter[key] = value;
      }
      return _filter;
    });
  };

  useEffect(() => {
    setFilteredProducts(
      products.filter((product) => {
        const categoryId = filter["cartegory"];
        const packageId = filter["package"];

        if (categoryId) {
          if (product?.category?._id !== categoryId) {
            return false;
          }
        }
        if (packageId) {
          if (product?.studentPackage?._id !== packageId) {
            return false;
          }
        }
        return true;
      })
    );
  }, [filter]);

  const handleEditProduct = (row) => () => {
    navigate("/admin/create-product", {
      state: { edit: true, product: row },
    });
  };

  const handleArchiveProduct = (row) => async () => {
    await apiClient.delete("/api/product/" + row._id);
    refeshProducts();
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <img
          className="product-img-preview"
          src={
            (process.env.REACT_APP_S3_BASE_URL ||
              "https://production-furnitrade-data.s3.us-east-2.amazonaws.com/") +
            row.imgs[0]
          }
          alt="img"
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Student Package",
      selector: (row) => row?.studentPackage?.name,
    },
    {
      name: "Category",
      selector: (row) => row?.category?.name,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="button black-bg white"
          onClick={handleEditProduct(row)}
        >
          Update
        </button>
      ),
    },
    {
      name: "Archive",
      selector: (row) =>
        row?.archive ? (
          "Archived"
        ) : (
          <button
            className="button black-bg white"
            onClick={handleArchiveProduct(row)}
          >
            Archive
          </button>
        ),
    },
  ];

  return (
    <div className="admin-right">
      <h4>Products</h4>

      <Link
        to={"/admin/create-product"}
        className="button outline black create-button"
      >
        Create Product
      </Link>
      <div className="form-group">
        <label>Select Category</label>
        <select
          value={filter["cartegory"]}
          className="select filter"
          onChange={(e) => {
            setCookie("__category", e.target.value);
            filterProducts("cartegory", e.target.value);
          }}
        >
          <option value={undefined}>All</option>
          {category.map((item) => (
            <option key={item._id} value={item._id}>
              {" "}
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Select Package</label>
        <select
          value={filter["package"]}
          className="select filter"
          onChange={(e) => {
            setCookie("__package", e.target.value);
            filterProducts("package", e.target.value);
          }}
        >
          <option value={undefined}>All</option>
          {packages.map((item) => (
            <option key={item._id} value={item._id}>
              {item?.parentStudentPackage?.name} - {item.name}
            </option>
          ))}
        </select>
      </div>

      <DataTable columns={columns} data={filteredProducts} pagination />
    </div>
  );
}
