import React, { useEffect } from "react";

export function ComingSoon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="view-login container fill">
        <section className="left thankyou">
          <h1>Coming Soon...</h1>

          <p>
            This page is under construction! We will be releasing it soon! Stay
            Tuned!
          </p>
        </section>
      </div>
    </>
  );
}
