import React, { useEffect, useState } from "react";
import { getBasicClient } from "../services";

export function FurnitureReplacement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [file, setFile] = useState();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!name || !email || !description)
      return setError("Please enter mandatory fields!");

    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("description", description);
    formData.append("file", file);

    const apiClient = getBasicClient();
    const replacementRequestRes = await apiClient.post(
      "/api/order/replace-request",
      formData
    );

    if (replacementRequestRes.status === 200) {
      setSuccessMessage("Furniture replacement request raised successfully!");
      setError("");
    } else {
      setError(replacementRequestRes.message);
      setSuccessMessage("");
    }
  };

  const handleFileChange = (e) => {
    const maxSize = 500 * 1024;
    if (e.target.files[0].size > maxSize) {
      setError("file size should be less then " + maxSize / 1024 + "kb");
      return;
    } else {
      setError("");
    }

    setSuccessMessage("");
    setFile(e.target.files[0]);
  };

  return (
    <div className="view-login container fill">
      <div className="left">
        <h3>Broken Furniture Report</h3>
        <p className="margin-top-30">
          Don't sweat it, accidents happen! That's why we cover 35% of cost to
          replace your furniture.
        </p>
        <p className="margin-top-10">
          After assessing the damage, we'll send an invoice to your email. Once
          all necessary fees are paid, we'll send over a replacement piece!
        </p>
        <form method="post" className="login" onSubmit={handleSubmit}>
          <label className="fixed" htmlFor="name">
            Name
          </label>
          <input
            className="input box"
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label className="fixed" htmlFor="email">
            Email
          </label>
          <input
            className="input box"
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
          />

          <label className="fixed" htmlFor="description">
            Description
          </label>
          <textarea
            style={{ width: "100%" }}
            className="input box"
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <label className="fixed" htmlFor="product-img">
            Attach image
          </label>
          <input
            type="file"
            id="product-img"
            className="file-input"
            name={"files"}
            onChange={(e) => handleFileChange(e)}
            accept="image/png, image/jpeg, image/jpg"
          />

          <div className="margin-top-30">
            <div className=" error">
              <p>{error}</p>
            </div>
            <div className=" success">
              <p>{successMessage}</p>
            </div>
          </div>

          <input
            className="button black-bg white"
            id="login_submit"
            name="login_submit"
            type="submit"
            value="Submit"
          />
        </form>
      </div>
    </div>
  );
}
