import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAPIClient } from "../services";
import moment from "moment";

export function MyOrders() {
  const { isLoggedIn, data: userData } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { _id } = userData || {};
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const apiClient = getAPIClient();
    apiClient
      .get("/api/order", {
        params: { userId: _id },
      })
      .then((orders) => setOrders(orders.data));
  }, [isLoggedIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="view-shop app-static-shop container fill maxwidth">
        <section className="shop-listing maxwidth">
          <h1 className="title">My Orders</h1>

          {orders &&
            orders.map((order, index) => {
              return (
                <div className="order" key={order._id}>
                  <h5>#{index + 1}</h5>
                  <h6>
                    <b>Package Name:</b> {order.studentPackage.name}
                  </h6>
                  <p>
                    <b>Price:</b> ${order.price}
                  </p>
                  <p>
                    <b>Delivery Date:</b>{" "}
                    {moment(order.deliveryDate).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <b>Delivery Time Slot:</b> {order.deliveryTimeSlot}
                  </p>
                  <p>
                    <b>Pickup Date:</b>{" "}
                    {moment(order.pickupDate).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <b>Pickup Time Slot:</b> {order.pickupTimeSlot}
                  </p>
                </div>
              );
            })}
        </section>
      </div>
    </>
  );
}
