import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChecking: false,
  isLoggedIn: false,
  token: null,
  data: null,
  errorMessage: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveTokenAction: (state, action) => {
      state.token = action.payload;
    },
    saveUserData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.isLoggedIn = true;
    },
    logoutAction: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.data = null;
    },
    startChecking: (state) => {
      state.isChecking = true;
    },
    stopChecking: (state) => {
      state.isChecking = false;
    },
    updateErrorMessage: (state, action) => {
      const message = action.payload;
      state.errorMessage = message;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = "";
    },
  },
});

export const {
  saveUserData,
  logoutAction,
  saveTokenAction,
  startChecking,
  stopChecking,
  updateErrorMessage,
  clearErrorMessage,
} = userSlice.actions;
export default userSlice.reducer;
