import React, { useEffect, useState } from "react";

export function Modal({ modalVisible, setModalVisible, children }) {
  return (
    <div
      id="myModal"
      className={modalVisible ? "custom-modal block" : "custom-modal"}
    >
      <div className="modal-content">
        <span onClick={() => setModalVisible(!modalVisible)} className="close">
          &times;
        </span>
        {children}
      </div>
    </div>
  );
}
