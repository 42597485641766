import React, { useState } from "react";
import {
  bradImage,
  joeyImage,
  nicoleImage,
  tomImage,
  zenilImage,
} from "../../assets";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const reviews = [
  {
    userImage: joeyImage,
    review:
      "Their delivery team was professional and friendly. Will definitely use Furnitrade again!",
    author: "Joey, Loyola Marymount University",
    backgroundColor: "bg-green",
  },
  {
    userImage: tomImage,
    review:
      "Finding my furniture couldn't have been any easier. A few clicks and my furniture was delivered right to my doorstep",
    author: "Tom, Loyola Marymount University",
    backgroundColor: "bg-blue",
  },
  {
    userImage: zenilImage,
    review: "They made the process of moving in and out seamless!",
    author: "Zenil, CSULB",
    backgroundColor: "bg-red",
  },
  {
    userImage: bradImage,
    review:
      "High quality furniture at the most affordable price on the market. On top of that, no hidden fees for delivery and pickup",
    author: "Brad, UCLA",
    backgroundColor: "bg-green",
  },
  {
    userImage: nicoleImage,
    review:
      "Loved their selection of furniture to choose from, so many stylish options!",
    author: "Nicole, UCLA",
    backgroundColor: "bg-blue",
  },
];

export function Review() {
  const [currentReview, setCurrentReview] = useState(0);

  return (
    <div className={`review-cover ${reviews[currentReview].backgroundColor}`}>
      <section className="padding-top-50 padding-bottom-50 maxwidth">
        <Carousel
          showThumbs={false}
          showStatus={false}
          interval={4000}
          autoPlay={true}
          infiniteLoop={true}
          onChange={(index) => setCurrentReview(index)}
        >
          {reviews.map((review, index) => (
            <div className="review" key={index}>
              <img src={review.userImage} alt={"sophia"} />
              <div>
                <p className="title">
                  <b>{review.review}</b>
                </p>
                <p className="author">- {review.author}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </section>
    </div>
  );
}
