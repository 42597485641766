import React, { useEffect } from "react";
import { StudentPackageList as StudentPackageListComponent } from "../components/StudentPackage";

export function StudentPackageList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <StudentPackageListComponent />;
}
