import React, { useEffect } from "react";
import { CategoryForm as CategoryFormComponent } from "../components/Category";

export function AdminCategoryForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <CategoryFormComponent />;
}
