import React, { useEffect } from "react";
import { CreateProduct as CreateProductComponent } from "../components/Product";

export function CreateProduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <CreateProductComponent />;
}
