import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getAPIClient } from "../../services/api";

export function Footer() {
  const [email, setEmail] = useState("");
  const [resMsg, setResMsg] = useState("");

  const handleSubscribeSubmit = async (e) => {
    e.preventDefault();

    const { data } = await getAPIClient().post("/api/subscriber", { email });
    setResMsg(data.message);

    setEmail("");
    setTimeout(() => setResMsg(""), 2000);
  };

  return (
    <footer className="app-footer">
      <div className="container">
        <div className="four-col">
          <nav className="company">
            <h5>Company</h5>
            <Link to={"/social"}>Mission Statement</Link>
            <br />
            <Link to={"/coming-soon"}>Blog</Link>
            <br />
            <a href="mailto:order@furnitrade.co">Contact Us</a>
            <br />
            <Link to={"/coming-soon"}>Careers</Link>
            <br />
            <Link to={"/ambassador"}>Become an Ambassador</Link>
            <br />
            <a href={"/?section=faq"}>FAQ</a>
          </nav>
          <nav className="legal">
            <h5>Legal</h5>
            <Link to={"/coming-soon"}>Terms &amp; Conditions</Link>
            <br />
            <Link to={"/coming-soon"}>Privacy Policy</Link>
            <br />
            <Link to={"/coming-soon"}>Purchase Agreement</Link>
          </nav>
          <nav className="social">
            <h5>Let's be friends</h5>
            <a href="mailto:order@furnitrade.co">
              <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#FFF"
                  fillRule="evenodd"
                  d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/furnitrade.co"
              target="_blank"
              title="Follow us on Instagram"
            >
              <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
                <title>Instagram Logo</title>
                <path
                  d="M21 21.75H3a.75.75 0 0 1-.75-.75V9.75h3c-.39.563-.523 1.612-.523 2.278 0 4.016 3.275 7.284 7.3 7.284 4.027 0 7.302-3.268 7.302-7.284 0-.666-.095-1.7-.579-2.278h3V21a.75.75 0 0 1-.75.75M12.028 7.424a4.61 4.61 0 0 1 4.615 4.604 4.61 4.61 0 0 1-4.615 4.605 4.61 4.61 0 0 1-4.615-4.605 4.61 4.61 0 0 1 4.615-4.605M18 3h2.25a.75.75 0 0 1 .75.75V6a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V3.75A.75.75 0 0 1 18 3m3.147-3H2.853A2.85 2.85 0 0 0 0 2.846v18.308A2.85 2.85 0 0 0 2.853 24h18.294A2.85 2.85 0 0 0 24 21.154V2.846A2.85 2.85 0 0 0 21.147 0"
                  fill="#FFF"
                  fillRule="evenodd"
                />
              </svg>
            </a>
          </nav>
          <nav className="newsletter">
            <h5>Stay Connected!</h5>
            <div>
              <div>
                <b>Phone:</b> (909) 345-3422
              </div>
              <div>
                <b>Delivery Range Hours:</b> Friday-Sunday 10am-8pm
              </div>
            </div>
            <br />
            <div>
              <div>
                <b>Subscribe to get updates!</b>
              </div>
              <br />
              <form className="subscribe" onSubmit={handleSubscribeSubmit}>
                <input
                  className="input box email"
                  id="email"
                  name="email"
                  type="text"
                  value={email}
                  placeholder="Your Email"
                  onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                  required={true}
                />
                <br />

                <input className="button" type="submit" value="Subscribe" />
              </form>
              {resMsg && resMsg}
            </div>
          </nav>
        </div>
        <div className="copyright">
          © 2022 Funitrade. ·
          <a href="https://www.furnitrade.co/" target="_blank">
            furnitrade.co
          </a>
          · Made with <span className="beat">❤</span> for the people and the
          planet.
        </div>
      </div>
    </footer>
  );
}
