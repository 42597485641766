import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAPIClient, getBasicClient } from "../../services";

const FileInput = (props) => {
  const { index, files, handleFileChange, removeFile } = props;
  const file = files?.[index];

  return (
    <div className="img-box-outer">
      {file ? (
        file instanceof File ? (
          <div className="img-preview">
            <img src={URL.createObjectURL(file)} alt="preview" />
            <button className="img-remove-button" onClick={removeFile(index)}>
              X
            </button>
          </div>
        ) : (
          <div className="img-preview">
            <img
              src={
                (process.env.REACT_APP_S3_BASE_URL ||
                  "https://production-furnitrade-data.s3.us-east-2.amazonaws.com/") +
                file
              }
              alt="preview"
            />
            <button className="img-remove-button" onClick={removeFile(index)}>
              X
            </button>
          </div>
        )
      ) : (
        <>
          <label htmlFor={`product-img-${index}`}>
            <span className="file-input-span">{"Upload Image " + index} </span>
          </label>
          <input
            type="file"
            id={`product-img-${index}`}
            className="file-input"
            name={"files" + index}
            onChange={handleFileChange(index)}
            accept="image/png, image/jpeg, image/jpg"
          />
        </>
      )}
    </div>
  );
};

export function CreateProduct() {
  const [productData, setProductData] = useState({});
  const [files, setFiles] = useState(Array(5).fill());
  const [category, setCategory] = useState([]);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const client = getAPIClient();
  const location = useLocation();
  const navigate = useNavigate();

  const [iseditMode, setIseditMode] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { edit, product } = location.state;
      if (edit !== undefined) {
        setIseditMode(edit);
      }
      if (product !== undefined) {
        setProductData(product);
        if (Array.isArray(product?.imgs) && product?.imgs?.length) {
          setFiles([...product.imgs], ...Array(5 - product.imgs.length).fill());
        }
      }
    }
  }, [location.state]);

  const refreshPackagesAndcategory = async () => {
    const { data: categoryData } = await client.get("/api/category");
    const {
      data: { studentPackages, parentStudentPackages },
    } = await client.get("/api/studentpackage");
    setCategory(categoryData);

    const tempParentPackages = {};
    parentStudentPackages.forEach((parentPackage) => {
      tempParentPackages[parentPackage._id.toString()] = parentPackage;
    });

    studentPackages.forEach((_package) => {
      _package["parentStudentPackage"] =
        tempParentPackages[_package.parentStudentPackage];
    });
    studentPackages.sort((a, b) =>
      a?.parentStudentPackage?.name.localeCompare(b?.parentStudentPackage?.name)
    );
    setPackages(studentPackages);

    setProductData((product) => {
      const _product = { ...product };
      if (!iseditMode) {
        _product.category = categoryData[0]._id;
        _product.studentPackage = studentPackages[0]._id;
      } else {
        const { product: productFromState } = location.state;
        _product.category = productFromState?.category?._id;
        _product.studentPackage = productFromState?.studentPackage?._id;
      }
      return _product;
    });
  };

  useEffect(() => {
    refreshPackagesAndcategory();
  }, [iseditMode]);

  const handleChange = (e) => {
    setSuccessMsg("");
    setError("");
    setProductData((productData) => ({
      ...productData,
      [e.target.name]: e.target.value,
    }));
  };

  const getInputProps = (key) => {
    let obj = { name: key, value: productData[key], onChange: handleChange };
    return obj;
  };

  const handleFileChange = (index) => (e) => {
    const maxSize = 500 * 1024;
    if (e.target.files[0].size > maxSize) {
      setError("file size should be less then " + maxSize / 1024 + "kb");
      return;
    } else {
      setError("");
    }
    setSuccessMsg("");
    setFiles((files) => {
      let _files = [...files];
      _files[index] = e.target.files[0];
      return _files;
    });
  };
  const removeFile = (index) => (e) => {
    setSuccessMsg("");
    setError("");
    setFiles((files) => {
      let _files = [...files];
      delete _files[index];
      return _files;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.filter((e) => e).length) {
      setError("One image must required!");
      return;
    }

    setError("");

    const formData = new FormData();

    const _product = JSON.parse(JSON.stringify(productData));

    for (let [index, file] of files.entries()) {
      if (!file) continue;
      formData.append("file" + index, file);
    }

    const productId = _product._id;
    delete _product._id;
    delete _product.imgs;
    for (let key in _product) {
      formData.append(key, _product[key]);
    }

    let data;
    if (iseditMode) {
      data = (await client.put("/api/product/" + productId, formData)).data;
    } else {
      data = (await client.post("/api/product", formData)).data;
    }

    navigate("./", { state: { edit: true, product: data.message } });

    setSuccessMsg(iseditMode ? "Updated!" : "Created!");
  };

  return (
    <div className="admin-right">
      <h4>{iseditMode ? "Update Product" : "Create Product"}</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("name")}
            required
          />
        </div>
        <div className="form-group">
          <label>Images</label>

          <div className="product-imgs-div">
            {Array(5)
              .fill()
              .map((event, index) => (
                <FileInput
                  key={index}
                  index={index}
                  handleFileChange={handleFileChange}
                  files={files}
                  removeFile={removeFile}
                />
              ))}
          </div>
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("price")}
            required
          />
        </div>
        <div className="form-group">
          <label>Store Credit</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("storeCredit")}
            required
          />
        </div>
        <div className="form-group">
          <label>Student Package</label>
          <select className="select" {...getInputProps("studentPackage")}>
            <option value={undefined} disabled>
              Selection
            </option>
            {packages.map((item) => (
              <option key={item._id} value={item._id}>
                {item?.parentStudentPackage?.name} - {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Category</label>
          <select className="select" {...getInputProps("category")}>
            <option value={undefined} disabled>
              Selection
            </option>
            {category.map((item) => (
              <option key={item._id} value={item._id}>
                {" "}
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Dimensions</label>
          <input
            type="text"
            className="input box"
            {...getInputProps("dimensions")}
            required
          />
        </div>
        {iseditMode && (
          <>
            <div className="form-group">
              <label>Unavailable</label>
              <select className="select" {...getInputProps("unavailable")}>
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Limited Availability</label>
              <select
                className="select"
                {...getInputProps("limitedAvailability")}
              >
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </select>
            </div>
          </>
        )}

        <div className="margin-top-10 error">
          <p>{error}</p>
        </div>
        <div className="margin-top-10 success">
          <p>{successMsg}</p>
        </div>

        <div className="form-group">
          <button type="submit" className="button black-bg white">
            {iseditMode ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
