import React from "react";
import { useSelector } from "react-redux";
import { ProductCard } from "../Product/ProductCard";

export function StudentPackageStepTwo(props) {
  const { goToPreviousPage, goToNextPage, data } = props;
  const { packageId } = data;

  const { products, packages } = useSelector((state) => state.studentPackages);
  const { items, errorMessage } = useSelector((state) => state.cart);

  const getProductFromCart = (productId) => {
    const product = items.find((item) => item.id == productId);
    return product
      ? { ...product, exists: true }
      : {
          exists: false,
        };
  };

  const productsByCategory = [
    ...new Set(products.map((product) => product.category._id)),
  ].reduce((obj, categoryId) => {
    const filteredProducts = products.filter(
      (product) => product.category._id === categoryId
    );
    const studentPackage = packages.find(
      (studentPackage) => studentPackage._id == packageId
    );
    const count = filteredProducts
      .map((product) => {
        const cartProduct = getProductFromCart(product._id);
        if (cartProduct.exists) {
          return cartProduct.quantity;
        }
        return 0;
      })
      .reduce((sum, ele) => sum + ele, 0);
    return {
      ...obj,
      [categoryId]: {
        products: filteredProducts,
        category: filteredProducts[0].category,
        count,
        maxCount: [
          "62ca68ebc6cd83f5d19c0382",
          "62ca68e1c6cd83f5d19c0381",
        ].includes(categoryId)
          ? 1
          : studentPackage.maxItemsPerCategory,
      },
    };
  }, {});

  return (
    <>
      <div className="page-head">
        <section className="maxwidth">
          <h4 className="title">Primary Items</h4>

          <div className="buttons">
            <button
              className="button black-bg white"
              onClick={goToPreviousPage}
            >
              {"<"}&nbsp; Previous
            </button>
            <button className="button black-bg white" onClick={goToNextPage}>
              Next &nbsp;{">"}
            </button>
          </div>
        </section>
      </div>

      <div className="view-shop app-static-shop container fill maxwidth">
        <section className="shop-listing maxwidth">
          <div className="error">
            <p>{errorMessage}</p>
          </div>

          {Object.keys(productsByCategory).map((categoryId) => (
            <div key={categoryId}>
              <h4 className="category-name">
                {productsByCategory[categoryId].category.name} ({" "}
                {productsByCategory[categoryId].count} /{" "}
                {productsByCategory[categoryId].maxCount} )
              </h4>
              <div className="product-grid">
                {productsByCategory[categoryId].products.map((product) => (
                  <ProductCard
                    key={product._id}
                    product={product}
                    categoryId={categoryId}
                    cartProduct={getProductFromCart(product._id)}
                    withCredit={false}
                    minHeight={"22px"}
                  />
                ))}
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
}
