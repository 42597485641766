import React, { useEffect } from "react";
import "./index.css";
import { XIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../redux/";

function ToastMessage(props) {
  const dispatch = useDispatch();
  const { message, type, timeout, id } = props;

  const handleCloseToast = () => {
    dispatch({ type: ACTIONS.REMOVE_TOAST_SAGA, payload: id });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: ACTIONS.REMOVE_TOAST_SAGA, payload: id });
    }, timeout);
  }, []);

  return (
    <div className="toast-wrapper">
      <h3>{message}</h3>
      <XIcon className="toast-close-icon" onClick={handleCloseToast} />
    </div>
  );
}

export function Toast() {
  const toasts = useSelector((state) => state.toasts).items;

  const toastMessages = toasts
    .filter((toast) => toast.visible)
    .map((toast) => <ToastMessage {...toast} key={toast.id} />);

  return <>{toastMessages}</>;
}
