import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAPIClient } from "../../services";
import DataTable from "react-data-table-component";
import { Modal } from "../Modal";
import moment from "moment";

export function AdminOrderList() {
  const { isLoggedIn, data: userData } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { _id } = userData || {};
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(-1);
  const [filter, setFilter] = useState({
    deliveryDate: new Date().toISOString().slice(0, 10),
  });
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      name: "Sr no.",
      selector: (row, index) => index,
    },
    {
      name: "Package Name",
      selector: (row) => row?.studentPackage?.name,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Delivery Date",
      selector: (row) => moment(row.deliveryDate).format("MM/DD/YYYY"),
    },
    {
      name: "Delivery Time Slot",
      selector: (row) => row.deliveryTimeSlot,
    },
    {
      name: "Pickup Date",
      selector: (row) => moment(row.pickupDate).format("MM/DD/YYYY"),
    },
    {
      name: "Pickup Time Slot",
      selector: (row) => row.pickupTimeSlot,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row, index) => (
        <button
          onClick={() => {
            setModalVisible(true);
            setSelectedOrder(index);
          }}
          className="button black-bg white"
        >
          View Details
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const apiClient = getAPIClient();
    apiClient.get("/api/order/all").then((orders) => {
      setOrders(orders.data);
      setFilteredOrders(orders.data);
    });
  }, [isLoggedIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filterOrders = (key, value) => {
    setFilter((filter) => {
      return { ...filter, [key]: value };
    });
  };

  useEffect(() => {
    setFilteredOrders(
      orders.filter((order) => {
        const deliveryDate = filter["deliveryDate"];

        if (
          deliveryDate &&
          order.deliveryDate.substring(0, 10) !== deliveryDate
        ) {
          return false;
        }

        return true;
      })
    );
  }, [filter]);

  return (
    <div className="admin-right">
      <h4>All Orders</h4>

      <div className="form-group">
        <label htmlFor="">Select Delivery Date</label>
        <input
          id="deliveryDate"
          type={"date"}
          className="date"
          value={filter["deliveryDate"]}
          onChange={(e) => filterOrders("deliveryDate", e.target.value)}
        />
      </div>

      <DataTable columns={columns} data={filteredOrders} pagination />

      <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
        {filteredOrders[selectedOrder] && (
          <>
            <h4>Order Details</h4>
            <hr />

            <h5 className="margin-top-10">Customer Details:</h5>
            <p>Name: {filteredOrders[selectedOrder].customerDetails.name}</p>
            <p>Email: {filteredOrders[selectedOrder].customerDetails.email}</p>
            <p>Phone: {filteredOrders[selectedOrder].customerDetails.phone}</p>
            <p>
              Address:{" "}
              {filteredOrders[selectedOrder].customerDetails?.address?.line1}{" "}
              {filteredOrders[selectedOrder].customerDetails?.address?.line2}
            </p>
            <p>
              Postal Code:{" "}
              {
                filteredOrders[selectedOrder].customerDetails?.address
                  ?.postal_code
              }
            </p>
            <p>
              City:{" "}
              {filteredOrders[selectedOrder].customerDetails?.address?.city}
            </p>
            <p>
              State:{" "}
              {filteredOrders[selectedOrder].customerDetails?.address?.state}
            </p>

            <h5 className="margin-top-10">Package Details:</h5>
            <p>Name: {filteredOrders[selectedOrder].studentPackage.name}</p>
            <p>Lease Term: {filteredOrders[selectedOrder].leaseTerm}</p>
            <p>Price: {filteredOrders[selectedOrder].price}</p>
            <p>
              Delivery Date:{" "}
              {moment(filteredOrders[selectedOrder].deliveryDate).format(
                "MM/DD/YYYY"
              )}
            </p>
            <p>
              Delivery Time Slot:{" "}
              {filteredOrders[selectedOrder]?.deliveryTimeSlot}
            </p>
            <p>
              Pickup Date:{" "}
              {moment(filteredOrders[selectedOrder].pickupDate).format(
                "MM/DD/YYYY"
              )}
            </p>
            <p>
              Pickup Time Slot: {filteredOrders[selectedOrder]?.pickupTimeSlot}
            </p>

            <h5 className="margin-top-10">Product Details:</h5>
            {filteredOrders[selectedOrder].products.map((product) => (
              <p>
                {product.id.name} - Quantity {product.quantity}
              </p>
            ))}
          </>
        )}
      </Modal>
    </div>
  );
}
