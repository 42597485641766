import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../redux";
import { Link, useNavigate } from "react-router-dom";
import { getBasicClient } from "../../services";

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { isLoggedIn, errorMessage } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(errorMessage);
    setSuccessMessage("");
  }, [errorMessage]);

  useEffect(() => {
    setError("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiClient = getBasicClient();
    const forgotPasswordRes = await apiClient.post("/auth/forgot-password", {
      email,
    });

    if (forgotPasswordRes.status === 200) {
      setSuccessMessage("Password reset link sent to you mail if user exists!");
      setError("");
    } else {
      setError(forgotPasswordRes.message);
      setSuccessMessage("");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="view-login container fill">
      <div className="left">
        <h3>Forgot Password</h3>
        <form className="login" id="login-form" onSubmit={handleSubmit}>
          <label className="fixed" htmlFor="email">
            Email
          </label>
          <input
            className="input box"
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
          />
          <div className="margin-top-30">
            <div className=" error">
              <p>{error}</p>
            </div>
            <div className=" success">
              <p>{successMessage}</p>
            </div>
          </div>

          <input
            className="button black-bg white"
            id="login_submit"
            name="login_submit"
            type="submit"
            value="Forgot Password"
          />

          <div className="login-signup-link">
            <Link to={"/signup"}>Create an account</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
