import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { businessModelImage, furnitureImage } from "../assets";

export function SocialResponsibility() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* start: home view */}
      <div className="home container fill">
        <aside className="app-cta overflow-bg">
          <div className="text">
            <div className="center">
              <h1>Our circular business model</h1>
              <p>
                Furnitrade is an online furniture rental platform dedicated to
                reducing waste through our circular business model of pre-owned
                furniture. Each piece of furniture that is rented is refurbished
                by in-house experts to maintain a consistent “like-new” quality
                on all items, so that every customer enjoys the same experience.
              </p>
              <Link to={"/howitworks"}>
                <button className="black-bg accent">How it Works</button>
              </Link>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${businessModelImage}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
        <aside className="app-cta overflow-bg reverse bg-blue">
          <div className="text">
            <div className="center">
              <h1>Furnish sustainably and reduce waste</h1>
              <p>
                Through Furnitrade, we can reduce the 9.8 million tons of
                furniture waste produced every year by renting pre-owned
                furniture. We buy pre-owned furniture and refurbish it to
                like-new quality, so that the furniture can be enjoyed by as
                many people as possible before going to waste.
              </p>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${furnitureImage}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
        <aside className="app-cta overflow-bg bg-red">
          <div className="text">
            <div className="center">
              <h1>Increasing the longevity of our furniture</h1>
              <p>
                Furniture that is no longer in like-new quality is refurbished
                one final time before being donated to local homeless shelters
                in Los Angeles.
              </p>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "none" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${furnitureImage}")`,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </aside>
      </div>
    </>
  );
}
