import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function AdminLayout() {
  const { isLoggedIn, data: userData } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && userData.type === "ADMIN") {
      navigate("products");
    } else {
      navigate("login");
    }
  }, []);

  return <>Loading..</>;
}
